import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useFingerprint } from '../../storage/useFingerprint'

const noPrivateBrowsingGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useFingerprint()

  // private (incognito) browsing detected
  if (to.name !== 'helpNoPrivateBrowsing' && store.isPrivate()) {
    next({ name: 'helpNoPrivateBrowsing' })
  } else {
    next()
  }
}

export { noPrivateBrowsingGuard }
