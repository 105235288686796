import axios from 'axios'
import query from './../../graphql/queries/queryBypassReasons'
import decodeToPromise from './typeChecker'
import * as io from 'io-ts'

export type BypassReasonContract = io.TypeOf<typeof bypassReason>

const bypassReason = io.type({
  hasDate: io.boolean,
  hasDescription: io.boolean,
  id: io.string,
  name: io.string,
})

const bypassReasonsTypeChecker = io.array(bypassReason)

export type messageResponse = {
  message: string
  code: number
}

const fetchBypassReasons = async (): Promise<BypassReasonContract[]> => {
  return await axios
    .get('/graphql', {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        query: query,
      },
    })
    .then((response) => response?.data?.data?.bypassReasons)
    .then(async (data) => await decodeToPromise(bypassReasonsTypeChecker, data))
}

export default fetchBypassReasons
