export default `
query SERVICE_RECORDS($userId: Int!, $page: String, $perPage: String, $searchQuery: String, $serviceDate: String) {
    serviceRecords(userId: $userId, page: $page, perPage: $perPage, searchQuery: $searchQuery, serviceDate: $serviceDate) {
      id
      date
      serviceName
      siteName
      frequencyCodes
    }
  }
`
