<template>
  <div class="absolute bg-gray-300 bg-opacity-70 h-screen left-0 right-0 top-0">
    <div
      class="bg-gray-100 border-gray-300 items-center justify-center left-12 p-2 relative rounded-md shadow-xl top-1/3 w-3/4"
      :class="identifier"
    >
      <p class="p-2 pb-20 text-left">
        <slot class="pb-20"></slot>
      </p>
      <div v-if="leftButtonShown" class="absolute left-2 pr-3 top-20 w-2/4">
        <BaseButton
          button-classes="text-center bg-blue-500 text-white rounded-sm"
          :action="leftButtonLabel"
          identifier="dialogue-left-button"
          :type="buttonType"
          @click="leftBtnRouteTo"
        ></BaseButton>
      </div>
      <div v-if="rightButtonShown" class="absolute pl-3 right-2 top-20 w-2/4">
        <BaseButton
          button-classes="text-center bg-blue-500 text-white rounded-sm"
          :action="rightButtonLabel"
          identifier="dialogue-right-button"
          :type="buttonType"
          @click="rightBtnRouteTo"
        ></BaseButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { ButtonHTMLAttributes, defineComponent, PropType } from 'vue'
  import BaseButton from './BaseButton.vue'
  import { useRouter } from 'vue-router'

  export default defineComponent({
    name: 'BaseDialogue',
    components: { BaseButton },
    props: {
      buttonType: {
        type: String as PropType<ButtonHTMLAttributes['type']>,
        default: 'button',
      },

      identifier: {
        type: String,
        default: '',
      },

      leftBtnRoute: {
        type: String,
        default: '',
      },

      leftButtonLabel: {
        type: String,
        default: 'button',
      },

      leftButtonShown: {
        type: Boolean,
        default: false,
      },

      rightBtnRoute: {
        type: String,
        default: '',
      },

      rightButtonLabel: {
        type: String,
        default: 'button',
      },

      rightButtonShown: {
        type: Boolean,
        default: false,
      },
    },

    emits: [
      'dialogueLeftButtonClicked',
      'dialogueRightButtonClicked',
      'clicked',
    ],

    setup(props, { emit }) {
      const router = useRouter()

      function leftBtnRouteTo(): void {
        router.push(props.leftBtnRoute)
        emit('dialogueLeftButtonClicked')
      }
      function rightBtnRouteTo(): void {
        router.push(props.rightBtnRoute)
        emit('dialogueRightButtonClicked')
      }

      return {
        leftBtnRouteTo,
        rightBtnRouteTo,
      }
    },
  })
</script>
