import ConfirmBypassSite from '../views/ConfirmBypassSite.vue'

export default {
  component: ConfirmBypassSite,
  meta: {
    back: 'selectBypassSites',
    requiresLocation: true,
    showMenu: true,
  },
  name: 'confirmBypassSite',
  path: '/sites/bypass/confirm',
}
