import axios from 'axios'
import querySiteServices from '../../graphql/queries/querySiteServices'
import queryAllServices from '../../graphql/queries/queryAllServices'
import * as io from 'io-ts'
import decodeToPromise from './typeChecker'

export type Service = io.TypeOf<typeof service>

const service = io.type({
  id: io.string,
  name: io.string,
  subServiceId: io.string,
})

const servicesTypeChecker = io.array(service)

export const getSiteServices = (
  siteId: number,
  contractorCompanyId: number
): Promise<Service[]> => {
  return axios
    .get('/graphql', {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        query: querySiteServices,
        variables: {
          contractorCompanyId: contractorCompanyId,
          siteId: siteId,
        },
      },
    })
    .then((response) => response?.data?.data?.siteServices)
    .then((data) => decodeToPromise(servicesTypeChecker, data))
}

export const getAllServices = (
  contractorCompanyId: number,
  customerId: number
): Promise<Service[]> => {
  return axios
    .get('/graphql', {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        query: queryAllServices,
        variables: {
          contractorCompanyId: contractorCompanyId,
          customerId: customerId,
        },
      },
    })
    .then((response) => response?.data?.data?.allServices)
    .then((data) => decodeToPromise(servicesTypeChecker, data))
}
