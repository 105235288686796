import ChangePin from '../views/ChangePin.vue'

export default {
  component: ChangePin,
  meta: {
    back: '',
    showMenu: true,
  },
  name: 'changePin',
  path: '/change-pin',
}
