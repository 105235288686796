import axios from 'axios'
import { CheckoutData } from '../../components/use/checkout'
import checkoutQuery from '../../graphql/mutations/checkout'
import * as io from 'io-ts'
import decodeToPromise from './typeChecker'

const checkoutResponseTypeChecker = io.type({
  code: io.number,
  message: io.string,
})

export type CheckoutResponse = io.TypeOf<typeof checkoutResponseTypeChecker>

export const postCheckoutData = async (
  checkoutData: CheckoutData
): Promise<CheckoutResponse> => {
  try {
    const response = await axios.post(
      '/graphql',
      {
        query: checkoutQuery,
        variables: { ...checkoutData },
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      }
    )
    const data = response?.data?.data?.checkout as CheckoutResponse
    return await decodeToPromise(checkoutResponseTypeChecker, data)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw error.message
  }
}
