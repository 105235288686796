<template>
  <div>
    <TitleBanner :title="siteName" :sub-title="siteAddress" />
    <h1 class="mx-4 my-2 pt-1 text-center text-lg">
      {{ selectedService?.name }}
    </h1>
    <h4 class="mx-4 my-2 pt-1">Please select one or more frequencies</h4>
    <div class="divide-gray-500 divide-y grid grid-cols-1 px-4">
      <div>
        <div
          v-for="frequency in frequencies"
          :key="frequencies.indexOf(frequency)"
          class="pb-2 relative"
        >
          <input
            v-model="selectedFrequency"
            type="checkbox"
            class="border-gray-500 h-4 rounded text-blue-500 w-4"
            :value="frequency?.id"
            @change="newOptionSelected($event.target)"
          />
          <label class="ml-3">{{ frequency?.description }}</label>
          <span
            v-if="frequencyCompletedBefore(frequency.id)"
            class="absolute right-2 text-blue-500 top-1"
          >
            <CheckCircleIcon class="h-5 w-5" />
          </span>
        </div>
      </div>
    </div>

    <div class="mb-2 pt-10 px-4">
      <BaseButton
        type="button"
        :disabled="selectedFrequency?.length === 0"
        action="CONTINUE"
        identifier="continueBtn"
        @click="continueBtnClicked"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import BaseButton from '../components/BaseButton.vue'
  import { useRoute, useRouter } from 'vue-router'
  import useSite from '../components/use/site'
  import useServices from '../components/use/services'
  import useFrequency from '../components/use/frequency'
  import {
    Frequency,
    getServiceFrequencies,
    getSiteServiceFrequencies,
  } from '../services/api/serviceFrequency'
  import TitleBanner from '../components/TitleBanner.vue'
  import { CheckCircleIcon } from '@heroicons/vue/solid'

  export default defineComponent({
    name: 'ServiceFrequency',
    components: { BaseButton, CheckCircleIcon, TitleBanner },

    async setup() {
      const { getSiteAddress, getSiteName, getSiteId } = useSite()
      const { setSelectedFrequency, getFreqCompletedBefore } = useFrequency()
      const { getSelectedService } = useServices()
      const router = useRouter()
      const route = useRoute()

      const canContinue = ref(false)
      const siteName = getSiteName()
      const siteAddress = getSiteAddress()
      const selectedService = getSelectedService()

      const siteId = getSiteId()
      const serviceId = getSelectedService().value?.id.toString()
      const subServiceId = getSelectedService().value?.subServiceId.toString()
      const selectedFrequency = ref<string[]>([])
      const frequencies = ref<Frequency[]>([])

      if (route?.params.serviceType === 'activeService') {
        await fetchSiteServiceFrequencies(siteId.value, serviceId, subServiceId)
        checkIfSkipPage()
      } else {
        await fetchServiceFrequencies(serviceId)
        checkIfSkipPage()
      }

      async function fetchSiteServiceFrequencies(
        siteId: number,
        serviceId: string,
        subServiceId: string
      ): Promise<void> {
        frequencies.value = await getSiteServiceFrequencies(
          siteId,
          serviceId,
          subServiceId
        )
      }

      async function fetchServiceFrequencies(serviceId: string): Promise<void> {
        frequencies.value = await getServiceFrequencies(serviceId)
      }

      function continueBtnClicked(): void {
        setSelectedFrequency(selectedFrequency.value)
        router.replace({ name: 'serviceForm' })
      }

      function newOptionSelected(event: EventTarget | null): void {
        const frequencyId = (event as HTMLInputElement)?.value
        if (frequencyId === '34') {
          selectedFrequency.value = ['34']
        } else if (selectedFrequency.value.includes('34')) {
          selectedFrequency.value.splice(selectedFrequency.value.indexOf('34'))
          selectedFrequency.value.push(frequencyId)
        }
      }

      function frequencyCompletedBefore(frequencyId: string) {
        const freqCompletionRecord = getFreqCompletedBefore().value
        const completionRecExists = freqCompletionRecord
          .filter((record) => record.serviceId.toString() === serviceId)
          .filter((record) => record.subServiceId.toString() === subServiceId)
          .find((record) => record.frequencyId.includes(frequencyId.toString()))

        return !!completionRecExists
      }

      function checkIfSkipPage() {
        if (frequencies.value.length === 1) {
          selectedFrequency.value = [frequencies.value[0].id]
          continueBtnClicked()
        }
      }

      return {
        canContinue,
        continueBtnClicked,
        frequencies,
        frequencyCompletedBefore,
        newOptionSelected,
        selectedFrequency,
        selectedService,
        siteAddress,
        siteName,
      }
    },
  })
</script>
