import { defineStore } from 'pinia'
import { ref } from 'vue'
import { BypassReasonContract } from '../services/api/BypassReason'

export const useBypass = defineStore(
  'bypass',
  () => {
    const selectedBypassReason = ref<BypassReasonContract>({
      hasDate: false,
      hasDescription: false,
      id: '',
      name: '',
    })
    const checkinDate = ref('')
    const reasonDescription = ref('')
    const scanBypassed = ref(false)

    const reset = () => {
      selectedBypassReason.value = {
        hasDate: false,
        hasDescription: false,
        id: '',
        name: '',
      }
      checkinDate.value = ''
      reasonDescription.value = ''
      scanBypassed.value = false
    }

    return {
      checkinDate,
      reasonDescription,
      reset,
      scanBypassed,
      selectedBypassReason,
    }
  },
  {
    persist: true,
  }
)
