import { api } from './api'
import { bannerText } from './app'
import { countries } from './countries'
import { debug } from './debug'
import { fingerprint } from './fingerprint'
import { googleMapApiKey } from './googleMap'
import { honeyBadger } from './honeyBadger'
import { radius } from './distance'
import { siteKey } from './reCaptcha'
import { perPage as sitesPerPage } from './showSitesPerPage'
import { redirects } from './redirects'
import { unauthorized } from './authorization'
import type { optionType } from './formOption'
import type { country } from './countries'
import type { instruction } from './instructions'
import type { SelectOption } from './selectOption'
import type {
  SchemaType,
  frequency,
  renderConditions,
  whenCondition,
  Answers,
} from './serviceFormTypes'

const config = {
  api: {
    capture: api,
  },
  app: {
    bannerText,
  },
  countries,
  debug,
  distance: {
    radius,
  },
  fingerprint,
  google: {
    map: {
      key: googleMapApiKey,
    },
  },
  honeyBadger,
  pagination: {
    sites: {
      perPage: sitesPerPage,
    },
  },
  recaptcha: {
    siteKey,
  },
  redirects,
  unauthorized,
}

export type {
  Answers,
  country,
  frequency,
  instruction,
  optionType,
  renderConditions,
  SelectOption,
  SchemaType,
  whenCondition,
}

export default config
