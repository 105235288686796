import ServiceFrequency from '../views/ServiceFrequency.vue'

export default {
  component: ServiceFrequency,
  meta: {
    back: 'serviceSelection',
    showMenu: true,
  },
  name: 'serviceFrequency',
  path: '/services/frequencies/:serviceType',
}
