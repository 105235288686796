import Help from '../views/menu/UserHelp.vue'

export default {
  component: Help,
  meta: {
    back: '',
    showMenu: true,
  },
  name: 'help',
  path: '/help',
}
