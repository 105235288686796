<template>
  <div class="relative">
    <div class="p-4">
      <TitleBanner :title="title" />
      <form>
        <section class="mt-7">
          <div>Enter phone number to begin:</div>
          <PhoneNumberInput
            :initial-phone-number="phoneNumber"
            :default-country-code="country.countryCode"
            @selected-country="countryUpdated"
            @phone-number-entered="phoneNumberEntered"
          />
        </section>
        <section v-if="country.showBtn && !pinVisible" class="mt-2 relative">
          <BaseButton
            type="button"
            action="Continue"
            class="continueButton"
            :disabled="!canContinue"
            @click="continueButtonClicked"
          />
        </section>
        <section v-if="pinVisible" class="mt-4">
          <div>
            <PinInput
              :pin-input-changed="pin"
              :pin-validation="isValid"
              autocomplete="current-password"
              @value-changed="validatePIN"
            />
            <div class="input-error mt-1 pl-2 text-red-500">
              {{ errorMessage }}
            </div>
          </div>
          <div class="mt-4 text-right">
            <a
              class="forgot-pin text-blue-400 hover:text-blue-500 active:text-blue-600 text-sm"
              aria-label="Forgot PIN?"
              @click="routeToForgotPin"
            >
              Forgot PIN?
            </a>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
  import { country } from '../config'
  import {
    computed,
    defineComponent,
    nextTick,
    onMounted,
    ref,
    watch,
  } from 'vue'
  import TitleBanner from '../components/TitleBanner.vue'
  import PinInput from '../components/PinInput.vue'
  import BaseButton from '../components/BaseButton.vue'
  import PhoneNumberInput from '../components/PhoneNumberInput.vue'
  import { useRouter } from 'vue-router'
  import usePhoneNumber from '../components/use/phoneNumber'
  import useUser from '../components/use/user'
  import useCountry from '../components/use/countries'
  import useUserLogin from '../components/use/userLogin'
  import fetchUserByPhone, { authenticateUser } from '../services/api/user'
  import setFocus from '../components/helpers/setFocus'

  export default defineComponent({
    name: 'UserLogin',
    components: {
      BaseButton,
      PhoneNumberInput,
      PinInput,
      TitleBanner,
    },

    setup() {
      const { getPhoneNumber, setPhoneNumber } = usePhoneNumber()
      const {
        getUserAuthenticationResult,
        setUserAuthenticationResult,
        setUserFirstName,
        setUserId,
        setUserPhoneNumber,
        setUserContractorEmployeeId,
        setSelectedContractorCompanyId,
        setUserContractorCompanies,
      } = useUser()
      const { getCountryByInternationalPhoneNumber } = useCountry()
      const {
        getUserLoginExists,
        getUserLoginFirstName,
        setUserLoginExists,
        setUserLoginFirstName,
        setUserLoginIsVisitor,
      } = useUserLogin()
      const router = useRouter()
      const canContinue = ref(false)
      const errorMessage = ref('')
      const phoneNumber = getPhoneNumber()
      const userExists = getUserLoginExists()
      const country = getCountryByInternationalPhoneNumber(phoneNumber.value)
      const pinVisible = ref(userExists.value)
      const isValid = computed(() => errorMessage.value === '')
      const title = computed(() => `Hi ${firstName.value}, Welcome`)
      const pin = ref('')
      const firstName = getUserLoginFirstName()
      const identityIsValid = getUserAuthenticationResult()

      onMounted(() => {
        enableContinueButton(phoneNumber.value)
        nextTick(function () {
          setFocus()
        })
      })

      function countryUpdated(c: country): void {
        country.value = c
      }

      async function checkUserExists(phoneNumber: string) {
        const user = await fetchUserByPhone(phoneNumber)
        setUserLoginExists(user.id > 0)
        setUserLoginFirstName(user.firstname)
        setUserLoginIsVisitor(user.isVisitor)
      }

      async function continueButtonClicked() {
        await checkUserExists(phoneNumber.value)
        setPhoneNumber(phoneNumber.value)
      }

      function enableContinueButton(phoneNumber: string): void {
        canContinue.value = !!phoneNumber
      }

      function phoneNumberEntered(phoneNumber: string): void {
        setPhoneNumber(phoneNumber)
        enableContinueButton(phoneNumber)
      }

      function validatePIN(pinEntered: string): void {
        const lengthOfPin = pinEntered.length
        const areNumbers = /^[0-9]+$/
        if (lengthOfPin !== 4) {
          errorMessage.value = '4 digits are required'
        } else if (lengthOfPin === 4 && !areNumbers.test(pinEntered)) {
          errorMessage.value = 'Please enter numbers only'
        } else if (lengthOfPin === 4 && areNumbers.test(pinEntered)) {
          errorMessage.value = ''
          pin.value = pinEntered
        }
      }

      function routeToForgotPin(): void {
        router.push({ name: 'forgotPin' })
      }

      async function checkUserIdentity(phoneNumber: string, pin: string) {
        const response = await authenticateUser(phoneNumber, pin)
        setUserAuthenticationResult(true)
        setUserFirstName(response.firstname)
        setUserId(response.id)
        setUserPhoneNumber(response.phone)
        setUserContractorEmployeeId(response.contractorEmployee.id)
        setUserContractorCompanies(response.contractorCompanies)
        response.contractorCompanies.length === 1 &&
          setSelectedContractorCompanyId(response.contractorCompanies[0].id)
      }

      watch(phoneNumber, async (phoneNumber: string) => {
        if (phoneNumber === '') {
          setUserLoginExists(false)
        }
        if (!country.value.showBtn && phoneNumber !== '') {
          await checkUserExists(phoneNumber)
        }
      })
      watch(userExists, (exists: boolean) => {
        pinVisible.value = exists
        nextTick(function () {
          setFocus()
        })
      })
      watch(pin, async (pin: string) => {
        if (pin === '') {
          setUserAuthenticationResult(false)
          return
        }
        await checkUserIdentity(phoneNumber.value, pin)
      })
      watch(identityIsValid, (validation: boolean) => {
        if (validation) {
          router.push({ name: 'selectNearbySite' })
        }
      })

      return {
        canContinue,
        continueButtonClicked,
        country,
        countryUpdated,
        errorMessage,
        identityIsValid,
        isValid,
        phoneNumber,
        phoneNumberEntered,
        pin,
        pinVisible,
        routeToForgotPin,
        title,
        validatePIN,
      }
    },
  })
</script>
