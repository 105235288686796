import axios from 'axios'
import queryServiceForm from '../../graphql/queries/queryServiceForm'
import storeRecord from '../../graphql/mutations/storeServiceRecord'
import storePhoto from '../../graphql/mutations/storeFormPhoto'
import removePhoto from '../../graphql/mutations/removeFormPhoto'

import * as io from 'io-ts'
import decodeToPromise from './typeChecker'

const compulsoryFormType = io.type({
  component: io.string,
  name: io.string,
  show: io.boolean,
})

const option = io.type({
  label: io.string,
  value: io.union([io.string, io.number]),
})

const frequency = io.type({
  frequency: io.array(io.number),
})

const whenCondition = io.partial({
  eq: io.string,
  ge: io.string,
  gt: io.string,
  le: io.string,
  lt: io.string,
})

const ComponentRenderConditions = io.type({
  do: io.string,
  on: io.string,
  when: io.union([io.array(whenCondition), io.array(frequency)]),
})

const partialFormType = io.partial({
  conditions: io.union([
    ComponentRenderConditions,
    io.array(ComponentRenderConditions),
  ]),
  content: io.string,
  controlPosition: io.string,
  level: io.number,
  max: io.number,
  min: io.number,
  multiline: io.boolean,
  options: io.union([io.array(io.string), io.array(option)]),
  placeholder: io.string,
  question: io.string,
  readonly: io.boolean,
  required: io.boolean,
  rules: io.array(io.string),
  specialOption: io.union([io.string, io.array(io.string)]),
  type: io.string,
})

const formTypeChecker = io.array(
  io.intersection([compulsoryFormType, partialFormType])
)

export type Form = io.TypeOf<typeof formTypeChecker>

export const getServiceForm = async (
  customerId: string,
  serviceId: string,
  frequencyIds: string
): Promise<{ original: string; validated: Form }> => {
  const response = await axios.get('/graphql', {
    headers: {
      'Content-type': 'application/json',
    },
    params: {
      query: queryServiceForm,
      variables: {
        customerId: parseInt(customerId),
        frequencyIds: frequencyIds,
        serviceId: parseInt(serviceId),
      },
    },
  })
  const original = response?.data?.data?.form?.schema
  const validated = await decodeToPromise(
    formTypeChecker,
    JSON.parse(original).data.schema
  )
  return {
    original: original,
    validated: validated,
  }
}

export const storeServiceRecord = async (
  deviceInfo: string,
  jobStart: string,
  jobEnd: string,
  userId: string,
  customerId: string,
  siteId: string,
  serviceId: string,
  subServiceId: string,
  frequencies: string,
  systemRemark: string,
  visitLogId: string,
  contractorCompanyId: number,
  lat: number,
  long: number,
  alt: number | null,
  accuracy: number,
  formData: string,
  schema: string
): Promise<number | undefined> => {
  const response = await axios.post(
    '/graphql',
    {
      query: storeRecord,
      variables: {
        accuracy: accuracy,
        alt: alt,
        contractorCompanyId: contractorCompanyId,
        customerId: customerId,
        deviceInfo: deviceInfo,
        formData: formData,
        frequencies: frequencies,
        geoLocation: lat + '*' + long,
        jobEnd: jobEnd,
        jobStart: jobStart,
        lat: lat,
        long: long,
        originalSchema: schema,
        serviceId: serviceId,
        siteId: siteId,
        subServiceId: subServiceId,
        systemRemark: systemRemark,
        userId: userId,
        visitLogId: visitLogId,
      },
    },
    {
      headers: {
        'Content-type': 'application/json',
      },
    }
  )

  return response?.data?.data?.storeServiceRecord?.id
}

export const storeFormPhoto = async function (
  serviceId: string,
  customerId: string,
  siteId: string,
  subServiceId: string,
  userId: string,
  inspectionPhoto: string | null = null,
  defectPhoto: string | null = null,
  sequenceOfPhoto: string
) {
  const response = await axios.post('/graphql', {
    query: storePhoto,
    variables: {
      customerId: customerId,
      defectPhoto: defectPhoto,
      inspectionPhoto: inspectionPhoto,
      sequence: sequenceOfPhoto,
      serviceId: serviceId,
      siteId: siteId,
      subServiceId: subServiceId,
      userId: userId,
    },
  })
  return response?.data?.data?.storePhoto?.photoId
}

export const removeFormPhoto = async function (photoId: string) {
  const response = await axios.post('/graphql', {
    query: removePhoto,
    variables: {
      photoId: photoId,
    },
  })
  return response?.data?.data?.deletePhoto?.code
}
