<template>
  <div>
    <div class="p-4">
      <TitleBanner title="Select Site (Bypass)" />
    </div>
    <div class="mt-4 mx-2">
      <BaseSelector
        label="Bypass records are reported separately"
        :label-classes="labelClass"
        :options="bypassReasons"
        default-option="Please select your bypass reason"
        @option-changed="reasonSelected"
      >
      </BaseSelector>
    </div>
    <div v-if="selectedReason?.hasDate" class="mt-4 mx-2 relative">
      <Datepicker
        v-model="checkinDate"
        :lower-limit="minCheckInDate"
        :upper-limit="maxCheckInDate"
        class="border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 rounded-md w-full"
      ></Datepicker>
      <span v-if="!checkinDate" class="absolute bottom-2 left-2"
        >Checkin Date
      </span>
      <span class="absolute bottom-1.5 pointer-events-none right-3">
        <svg
          class="h-8 text-black w-8"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <rect x="4" y="5" width="16" height="16" rx="2" />
          <line x1="16" y1="3" x2="16" y2="7" />
          <line x1="8" y1="3" x2="8" y2="7" />
          <line x1="4" y1="11" x2="20" y2="11" />
          <rect x="8" y="15" width="2" height="2" />
        </svg>
      </span>
    </div>
    <div v-if="selectedReason?.hasDescription" class="mt-4 mx-2">
      <BaseTextarea
        v-model="reasonDescription"
        placeholder="Bypass Reason"
        :textarea-classes="textareaClasses"
        :required="textareaRequired"
        :maxlength="textareaMaxlength"
      >
      </BaseTextarea>
    </div>
    <div class="mt-4 mx-2">
      <BaseButton
        class="continue"
        type="button"
        :disabled="!selectedReason"
        action="CONTINUE"
        @click="confirmBypass"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue'
  import TitleBanner from '../components/TitleBanner.vue'
  import BaseButton from '../components/BaseButton.vue'
  import BaseSelector from '../components/BaseSelector.vue'
  import BaseTextarea from '../components/BaseTextarea.vue'
  import Datepicker from 'vue3-datepicker'
  import fetchBypassReasons, {
    BypassReasonContract,
  } from '../services/api/BypassReason'
  import { useRouter } from 'vue-router'
  import useBypass from '../components/use/bypass'
  import dayjs from 'dayjs'

  export default defineComponent({
    name: 'BypassSite',
    components: {
      BaseButton,
      BaseSelector,
      BaseTextarea,
      Datepicker,
      TitleBanner,
    },

    async setup() {
      const router = useRouter()
      const error = ref('')
      const textareaShowed = ref(false)
      const datepickerShowed = ref(false)
      const selectedReason = ref<BypassReasonContract>()
      const labelClass = ref(['text-center', 'text-red-500'])
      const textareaClasses = ref(['h-20'])
      const textareaRequired = ref(true)
      const textareaMaxlength = ref(30)
      const reasonDescription = ref('')
      const checkinDate = ref<Date | undefined>(undefined)
      const bypassReasons = ref<BypassReasonContract[]>()
      const {
        setSelectedBypassReason,
        setCheckinDate,
        setReasonDescription,
        resetBypass,
      } = useBypass()
      const minCheckInDate = ref(dayjs().subtract(6, 'month').toDate())
      const maxCheckInDate = ref(dayjs().toDate())

      onMounted(() => {
        resetBypass()
      })

      const getBypassReasons = async () => {
        bypassReasons.value = await fetchBypassReasons()
      }

      await getBypassReasons()

      function confirmBypass(): void {
        setSelectedBypassReason(selectedReason.value as BypassReasonContract)
        checkinDate.value &&
          setCheckinDate(formatAndConvertDate(checkinDate.value))
        reasonDescription.value && setReasonDescription(reasonDescription.value)
        router.push({ name: 'selectBypassSites' })
      }

      function reasonSelected(selected: string): void {
        checkinDate.value = undefined
        reasonDescription.value = ''
        selectedReason.value = bypassReasons?.value?.find(
          (reason: BypassReasonContract): boolean => {
            return reason.id == selected
          }
        )
      }

      function formatAndConvertDate(date: Date | string): string {
        return dayjs(date).format('DD-MM-YYYY')
      }

      return {
        bypassReasons,
        checkinDate,
        confirmBypass,
        datepickerShowed,
        error,
        labelClass,
        maxCheckInDate,
        minCheckInDate,
        reasonDescription,
        reasonSelected,
        selectedReason,
        textareaClasses,
        textareaMaxlength,
        textareaRequired,
        textareaShowed,
      }
    },
  })
</script>
