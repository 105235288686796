export default `
query USER_QUERY($phone: String!) {
  user(phone: $phone) {
    id
    firstname
    lastname
    name
    phone
    isVisitor
  }
}
`
