import { defineStore } from 'pinia'
import { ref } from 'vue'
import { RouteParams, RouteRecordName } from 'vue-router'

export declare type NamedRoute = {
  name: RouteRecordName
  params?: RouteParams
}

export const savePoint = defineStore(
  'savePoint',
  () => {
    const savePoint = ref({ name: '' } as NamedRoute)

    const reset = () => {
      savePoint.value = {
        name: '',
      } as NamedRoute
    }

    return {
      reset,
      savePoint,
    }
  },
  {
    persist: true,
  }
)
