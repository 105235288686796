export default function scroll(fn: () => void): {
  addScrollListener: () => void
  removeScrollListener: () => void
} {
  function addScrollListener(): void {
    document.addEventListener('scroll', handleScroll)
  }

  function removeScrollListener(): void {
    document.removeEventListener('scroll', handleScroll)
  }

  const handleScroll = (): void => {
    const pageBottomReached =
      window.innerHeight + window.scrollY >= document.body.offsetHeight

    pageBottomReached && fn()
  }

  return {
    addScrollListener,
    removeScrollListener,
  }
}
