<template>
  <div v-show="shouldShow" class="my-4">
    <textarea
      v-model="inputValue"
      :placeholder="placeholder"
      :identifier="name"
      :minlength="min"
      :maxlength="max"
      :disabled="readonly"
      :class="{ 'placeholder-red-500': !!errorMessage }"
      class="border border-gray-500 disabled:opacity-50 duration-300 focus:ring-2 h-20 outline-none px-2 py-1 rounded-md text-gray-800 w-full"
    />
    <span class="mt-1 mb-1 text-red-500">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, watch } from 'vue'

  export default defineComponent({
    name: 'TextareaInput',

    props: {
      // eslint-disable-next-line vue/no-unused-properties
      conditions: {
        type: Array,
        default: () => undefined,
      },

      errorMessage: {
        type: String,
        default: '',
      },

      max: {
        type: Number,
        default: Infinity,
      },

      min: {
        type: Number,
        default: Infinity,
      },

      name: {
        type: String,
        default: '',
      },

      placeholder: {
        type: String,
        default: '',
      },

      readonly: {
        type: Boolean,
        default: false,
      },

      show: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['formChanged'],

    setup(props, { emit }) {
      const inputValue = ref('')
      const shouldShow = ref(props.show)
      const identifier = ref<string>(props.name)

      watch(inputValue, () => {
        emit('formChanged', inputValue.value)
      })

      return {
        identifier,
        inputValue,
        shouldShow,
      }
    },
  })
</script>
