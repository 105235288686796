import ServiceSelection from '../views/ServiceSelection.vue'

export default {
  component: ServiceSelection,
  meta: {
    showMenu: true,
  },
  name: 'serviceSelection',
  path: '/services/selection',
}
