import { defineStore } from 'pinia'
import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro'
import { Ref, ref } from 'vue'

export const useFingerprint = defineStore(
  'fingerprint',
  () => {
    const fingerprint: Ref<null | ExtendedGetResult> = ref(null)

    const getOs = () => fingerprint.value?.os
    const hasFingerprint = (): boolean => fingerprint.value !== null
    const isPrivate = (): boolean => fingerprint.value?.incognito === true
    const reset = () => {
      fingerprint.value = null
    }

    return { fingerprint, getOs, hasFingerprint, isPrivate, reset }
  },
  {
    persist: true,
  }
)
