import { Customer } from '../../services/api/site'
import { computed, ComputedRef } from 'vue'
import { useCustomer } from '../../storage/useCustomer'

export default function customer(): {
  getCustomerId: () => ComputedRef<string>
  getCustomerHasVisitorManagement: () => ComputedRef
  setCustomerInfo: (customer: Customer) => void
  resetCustomerData: () => void
} {
  const store = useCustomer()

  function getCustomerId(): ComputedRef<string> {
    return computed(() => store.id)
  }

  function getCustomerHasVisitorManagement(): ComputedRef<boolean> {
    return computed(() => store.hasVisitorManagement)
  }

  function setCustomerInfo(customer: Customer): void {
    store.id = customer.id
    store.name = customer.name
    store.street = customer.street
    store.town = customer.town
    store.postcode = customer.postcode
    store.state = customer.state
    store.phone = customer.phone
    store.email = customer.email
    store.hasInduction = customer.hasInduction
    store.hasWorkOrder = customer.hasWorkOrder
    store.hasVisitorManagement = customer.hasUnregisteredContractor
    store.hasCheckList = customer.hasCheckList
    store.hasNotice = customer.hasNotice
    store.hasGPS = customer.hasGPS
    store.hasAccreditation = customer.hasAccreditation
  }

  function resetCustomerData() {
    store.reset()
  }

  return {
    getCustomerHasVisitorManagement,
    getCustomerId,
    resetCustomerData,
    setCustomerInfo,
  }
}
