<template>
  <div class="w-full">
    <Listbox v-model="selectedCountry">
      <div class="mt-1 relative">
        <ListboxLabel
          class="absolute left-2 pl-1 text-blue-400 text-xs top-1"
          :class="{ 'opacity-50': disabled }"
        >
          Country Code
        </ListboxLabel>
        <ListboxButton
          class="border border-gray-200 disabled:opacity-50 duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400 h-12 px-2 py-1 rounded-md selector-button text-gray-800 text-left w-full"
          :class="classes"
          :disabled="disabled"
        >
          <span class="block pt-4 truncate">
            <span class="pl-1.5"></span>
            <CountryFlag :iso="selectedCountry.countryCode"></CountryFlag>
            <span class="pr-1"></span>
            {{ selectedCountry.countryCode }}
            <span class="pr-1"></span>
            {{ selectedCountry.countryCallingCode }}
          </span>
          <span
            class="absolute flex inset-y-0 items-center pointer-events-none pr-2 right-0"
          >
            <SelectorIcon class="h-5 text-gray-400 w-5" aria-hidden="true" />
          </span>
        </ListboxButton>

        <Transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="absolute bg-white focus:outline-none max-h-60 py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg sm:text-base text-base w-full z-10"
          >
            <ListboxOption
              v-for="country in countries"
              v-slot="{ active, selected }"
              :key="country.countryCode"
              :value="country"
              as="template"
              @click="selectCountry"
            >
              <li
                :class="[
                  active ? 'text-amber-900 bg-blue-500' : 'text-gray-900',
                  'cursor-default select-none relative',
                ]"
              >
                <div class="flex p-2">
                  <CountryFlag :iso="country.countryCode"></CountryFlag>
                  <span class="pr-1"></span>
                  <span
                    :class="[
                      active ? 'text-indigo-200' : 'text-gray-500',
                      'ml-1 truncate',
                    ]"
                  >
                    {{ country.countryCode }}
                    <span class="pr-1"></span>
                    {{ country.countryCallingCode }}
                  </span>

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-black' : 'text-blue-500',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </div>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </Transition>
      </div>
    </Listbox>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
  } from '@headlessui/vue'
  import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
  import config from '../config'
  import CountryFlag from './CountryFlag.vue'

  export default defineComponent({
    name: 'CountryCodeSelector',
    components: {
      CheckIcon,
      CountryFlag,
      Listbox,
      ListboxButton,
      ListboxLabel,
      ListboxOption,
      ListboxOptions,
      SelectorIcon,
    },

    props: {
      classes: {
        type: Array,
        default: () => [],
      },

      defaultCountryCode: {
        type: String,
        default: 'AU',
      },

      disabled: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['selectCountry'],

    setup(props) {
      const countries = config.countries

      const selectedCountry = ref(
        countries.find(
          (country) => country.countryCode == props.defaultCountryCode
        ) || countries[0]
      )

      return {
        countries,
        selectedCountry,
      }
    },

    methods: {
      selectCountry(): void {
        this.$emit('selectCountry', this.selectedCountry)
      },
    },
  })
</script>
