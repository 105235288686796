interface Config {
  endpoint: string
  token: string
}

export const fingerprint: Config = {
  endpoint:
    import.meta.env.VITE_FINGERPRINT_ENDPOINT ||
    'https://metrics.verified.com.au',
  token: import.meta.env.VITE_FINGERPRINT_TOKEN || '',
}
