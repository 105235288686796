import LocationHelp from '../views/help/LocationHelp.vue'

export default {
  component: LocationHelp,
  meta: {
    back: 'login',
    showMenu: false,
  },
  name: 'helpLocationPermission',
  path: '/help/position/permission',
}
