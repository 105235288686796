import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useForms = defineStore(
  'forms',
  () => {
    const serviceRecordId = ref('')

    const reset = () => {
      serviceRecordId.value = ''
    }

    return {
      reset,
      serviceRecordId,
    }
  },
  {
    persist: true,
  }
)
