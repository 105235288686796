import NoPrivateBrowsingHelp from '../views/help/NoPrivateBrowsingHelp.vue'

export default {
  component: NoPrivateBrowsingHelp,
  meta: {
    back: '',
    showMenu: false,
  },
  name: 'helpNoPrivateBrowsing',
  path: '/help/private-browsing',
}
