import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUserLogin = defineStore(
  'user/login',
  () => {
    const exists = ref(false)
    const firstName = ref('There')
    const isVisitor = ref(false)
    const phoneNumber = ref('')

    return { exists, firstName, isVisitor, phoneNumber }
  },
  {
    persist: true,
  }
)
