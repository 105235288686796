import { SiteSelectedResponse } from '../../services/api/siteSelected'

type DataAcquisitionFunction = () => Promise<SiteSelectedData>

export type SiteSelectedData = {
  bypass: Bypass | null
  contractorCompanyId: string
  location: Position
  siteId: string
  userId: string
}

type ApiCallFunction = (data: SiteSelectedData) => Promise<SiteSelectedResponse>

const siteSelected = async function (
  acquireData: DataAcquisitionFunction,
  apiCall: ApiCallFunction
): Promise<SiteSelectedResponse> {
  return await apiCall(await acquireData())
}

export default siteSelected
