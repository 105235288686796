import SelectBypassSite from '../views/SelectBypassSite.vue'

export default {
  component: SelectBypassSite,
  meta: {
    back: 'bypass',
    requiresLocation: true,
    showMenu: true,
  },
  name: 'selectBypassSites',
  path: '/sites/bypass',
}
