import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUserPosition = defineStore(
  'user/position',
  () => {
    const position = ref({
      accuracy: 0,
      altitude: 0,
      latitude: 0,
      longitude: 0,
    } as Position)

    const reset = () => {
      position.value = {
        accuracy: 0,
        altitude: 0,
        latitude: 0,
        longitude: 0,
      }
    }

    return { position, reset }
  },
  {
    persist: true,
  }
)
