import { computed, ComputedRef } from 'vue'
import { useForms } from '../../storage/useForm'

export default function form(): {
  getServiceRecordId: () => ComputedRef<string>
  setServiceRecordId: (serviceRecordId: string) => void
  resetFormStore: () => void
} {
  const store = useForms()

  function getServiceRecordId() {
    return computed(() => store.serviceRecordId)
  }

  function setServiceRecordId(serviceRecordId: string) {
    store.serviceRecordId = serviceRecordId
  }

  function resetFormStore() {
    store.reset()
  }

  return {
    getServiceRecordId,
    resetFormStore,
    setServiceRecordId,
  }
}
