import { computed, ComputedRef } from 'vue'
import { CompletableService } from '../../storage/useServices'
import { useServices } from '../../storage/useServices'
import { Service } from '../../services/api/services'

export default function services(): {
  getServices: () => ComputedRef<CompletableService[]>
  setServices: (allServices: CompletableService[]) => void
  resetServiceData: () => void
  getSelectedService: () => ComputedRef<Service>
  getSelectedServiceType: () => ComputedRef<string>
  hasSelectedService: () => ComputedRef<boolean>
  setSelectedService: (service: Service) => void
  setSelectedServiceType: (type: string) => void
  setServiceCompleted: (serviceId: string, subServiceId: string) => void
} {
  const store = useServices()

  function getServices(): ComputedRef<CompletableService[]> {
    return computed(() => store.allServices)
  }

  function setServices(allServices: CompletableService[]): void {
    store.setServices(allServices)
  }

  function setSelectedService(service: Service): void {
    store.selectedService = service
  }

  function setSelectedServiceType(type: string): void {
    store.selectedServiceType = type
  }

  function getSelectedService(): ComputedRef<Service> {
    return computed(() => store.selectedService)
  }

  function getSelectedServiceType(): ComputedRef<string> {
    return computed(() => store.selectedServiceType)
  }

  function hasSelectedService(): ComputedRef<boolean> {
    return computed(() => getSelectedService().value.id !== '')
  }

  function resetServiceData(): void {
    store.reset()
  }

  function setServiceCompleted(serviceId: string, subServiceId: string): void {
    store.setCompleted(serviceId, subServiceId)
  }

  return {
    getSelectedService,
    getSelectedServiceType,
    getServices,
    hasSelectedService,
    resetServiceData,
    setSelectedService,
    setSelectedServiceType,
    setServiceCompleted,
    setServices,
  }
}
