import { defineStore } from 'pinia'
import { ref } from 'vue'

export type CompletedFrequencyType = {
  frequencyId: string[]
  serviceId: string
  subServiceId: string
}

export const useFrequencies = defineStore(
  'frequencies',
  () => {
    const previouslyCompleted = ref([
      {
        frequencyId: [],
        serviceId: '',
        subServiceId: '',
      },
    ] as CompletedFrequencyType[])
    const selectedFrequency = ref([''])

    const reset = () => {
      previouslyCompleted.value = [
        {
          frequencyId: [],
          serviceId: '',
          subServiceId: '',
        },
      ]
      selectedFrequency.value = ['']
    }

    const setCompleted = (
      serviceId: string,
      subServiceId: string,
      frequencyId: string
    ) => {
      previouslyCompleted.value[0].serviceId === '' &&
        previouslyCompleted.value.shift()

      const completionRecordExists = !!previouslyCompleted.value.find(
        (record) =>
          record.serviceId === serviceId && record.subServiceId === subServiceId
      )?.serviceId

      completionRecordExists &&
        previouslyCompleted.value.forEach((frequency) => {
          if (
            frequency.serviceId !== serviceId ||
            frequency.subServiceId !== subServiceId
          ) {
            return null
          }

          frequency.frequencyId.push(frequencyId)
          frequency.frequencyId = [...new Set(frequency.frequencyId)]
        })

      !completionRecordExists &&
        previouslyCompleted.value.push({
          frequencyId: [frequencyId],
          serviceId: serviceId,
          subServiceId: subServiceId,
        })
    }

    return {
      previouslyCompleted,
      reset,
      selectedFrequency,
      setCompleted,
    }
  },
  {
    persist: true,
  }
)
