import { computed, ComputedRef } from 'vue'
import {
  CompletedFrequencyType,
  useFrequencies,
} from '../../storage/useFrequencies'

export default function frequency(): {
  getSelectedFrequency: () => ComputedRef<string[]>
  getFreqCompletedBefore: () => ComputedRef<CompletedFrequencyType[]>
  setSelectedFrequency: (frequency: string[]) => void
  setFreqCompletedBefore: (
    serviceId: string,
    subServiceId: string,
    frequencyId: string
  ) => void
  resetFrequency: () => void
} {
  const store = useFrequencies()

  function getSelectedFrequency(): ComputedRef<string[]> {
    return computed(() => store.selectedFrequency)
  }

  function getFreqCompletedBefore(): ComputedRef<CompletedFrequencyType[]> {
    return computed(() => store.previouslyCompleted)
  }

  function setSelectedFrequency(frequency: string[]): void {
    store.selectedFrequency = frequency
  }

  function setFreqCompletedBefore(
    serviceId: string,
    subServiceId: string,
    frequencyId: string
  ): void {
    store.setCompleted(serviceId, subServiceId, frequencyId)
  }

  function resetFrequency() {
    store.reset()
  }

  return {
    getFreqCompletedBefore,
    getSelectedFrequency,
    resetFrequency,
    setFreqCompletedBefore,
    setSelectedFrequency,
  }
}
