<template>
  <div>
    <TitleBanner :title="siteName" :sub-title="siteAddress" />
    <TitleBanner title="Please select a service" />
    <div class="ml-auto mr-auto mt-1 mt-4 w-11/12">
      <div v-if="servicesAtSite?.length === 0">
        No active services at this site
      </div>
      <ul v-if="servicesAtSite?.length !== 0">
        <li
          v-for="service in servicesAtSite"
          :key="servicesAtSite.indexOf(service)"
          class="border border-blue-500 mt-2 p-3 relative rounded text-blue-500"
          @click="selectedActiveService(service)"
        >
          <p class="w-5/6">{{ service?.name }}</p>
          <span
            v-if="!serviceIsCompleted(service.id, service.subServiceId)"
            class="absolute right-2 top-3.5"
          >
            <ChevronRightIcon class="h-5 w-5" />
          </span>
          <span
            v-if="serviceIsCompleted(service.id, service.subServiceId)"
            class="absolute right-2 top-3.5"
          >
            <CheckCircleIcon class="h-5 w-5" />
          </span>
        </li>
      </ul>
      <div v-if="addServiceOptions.length !== 0" class="add-service mt-8">
        <BaseSelector
          :options="addServiceOptions"
          default-option="Add a service"
          @option-changed="selectedOtherService($event)"
        ></BaseSelector>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import BaseSelector from '../components/BaseSelector.vue'
  import TitleBanner from '../components/TitleBanner.vue'
  import useSite from '../components/use/site'
  import useServices from '../components/use/services'
  import useUser from '../components/use/user'
  import useCustomer from '../components/use/customer'
  import {
    onBeforeRouteLeave,
    RouteLocationNormalized,
    useRouter,
  } from 'vue-router'
  import {
    getAllServices,
    getSiteServices,
    Service,
  } from '../services/api/services'
  import { SelectOption } from '../config'
  import { ChevronRightIcon } from '@heroicons/vue/outline'
  import { CheckCircleIcon } from '@heroicons/vue/solid'
  import { CompletableService } from '../storage/useServices'

  export default defineComponent({
    name: 'ServiceSelection',
    components: {
      BaseSelector,
      CheckCircleIcon,
      ChevronRightIcon,
      TitleBanner,
    },

    async setup() {
      const {
        getServices,
        setServices,
        setSelectedService,
        setSelectedServiceType,
      } = useServices()
      const { getSiteId, getSiteName, getSiteAddress } = useSite()
      const { getUserSelectedContractorCompanyId } = useUser()
      const { getCustomerId } = useCustomer()

      const router = useRouter()
      const siteId = getSiteId()
      const siteName = getSiteName()
      const siteAddress = getSiteAddress()
      const contractorCompanyId = getUserSelectedContractorCompanyId()
      const customerId = getCustomerId()
      const services = getServices()

      const servicesAtSite = ref<Service[]>([])
      const allContractorServices = ref<Service[]>([])
      const addServiceOptions = ref<SelectOption[]>([])

      onBeforeRouteLeave((to: RouteLocationNormalized) => {
        if (to.name === 'contractorCompanySelection') {
          return false
        }
      })

      await fetchSiteServices(siteId.value, contractorCompanyId.value)
      await fetchAllServices(
        contractorCompanyId.value,
        parseInt(customerId.value)
      )
      excludeSiteServices(servicesAtSite.value, allContractorServices.value)
      shouldSkipThisPage()

      const serviceIsCompleted = (
        serviceId: string,
        subServiceId: string
      ): boolean => {
        return (
          services.value.find((service) => {
            return (
              service?.id === serviceId &&
              service?.subServiceId === subServiceId
            )
          })?.completed || false
        )
      }

      function addCompletedPropertyAndSaveInStore(services: Service[]) {
        const completableServices = services.map((service) => {
          return { ...service, completed: false } as CompletableService
        })
        setServices(completableServices || [])
      }

      async function fetchSiteServices(
        siteId: number,
        contractorCompanyId: number
      ): Promise<void> {
        try {
          const response = await getSiteServices(siteId, contractorCompanyId)
          servicesAtSite.value = response as Service[]
        } catch (e) {
          // prevent Capture from showing the error to users if there is no active service
          // since non-active services might be available
        }
      }

      async function fetchAllServices(
        contractorCompanyId: number,
        customerId: number
      ): Promise<void> {
        const response = await getAllServices(contractorCompanyId, customerId)
        allContractorServices.value = response as Service[]
        addCompletedPropertyAndSaveInStore(allContractorServices.value)
      }

      function excludeSiteServices(
        servicesAtSite: Service[],
        allContractorServices: Service[]
      ): void {
        if (servicesAtSite.length === 0) {
          addServiceOptions.value = allContractorServices
        }
        addServiceOptions.value =
          allContractorServices?.filter((service) => {
            let identical = servicesAtSite?.filter(
              (siteService) =>
                siteService?.id.toString() === service?.id.toString()
            )
            return identical?.length === 0
          }) || []
      }

      function storeSelectedServiceAndRouteToFreqPage(
        service: Service,
        serviceType: string
      ) {
        setSelectedService(service)
        setSelectedServiceType(serviceType)
        router.push({
          name: 'serviceFrequency',
          params: { serviceType: serviceType },
        })
      }

      function selectedActiveService(s: Service) {
        const service = servicesAtSite.value.find((service) => {
          return service.id === s.id && service.subServiceId === s.subServiceId
        }) as Service
        storeSelectedServiceAndRouteToFreqPage(service, 'activeService')
      }

      function selectedOtherService(serviceId: number | string) {
        const service = services.value.find((service) => {
          return service.id === serviceId
        }) as Service
        storeSelectedServiceAndRouteToFreqPage(service, 'otherService')
      }

      function shouldSkipThisPage() {
        if (
          servicesAtSite.value.length === 1 &&
          addServiceOptions.value.length === 0
        ) {
          selectedActiveService(servicesAtSite.value[0])
        }

        if (
          servicesAtSite.value.length === 0 &&
          addServiceOptions.value.length === 1
        ) {
          selectedOtherService(addServiceOptions.value[0].id)
        }
      }

      return {
        addServiceOptions,
        selectedActiveService,
        selectedOtherService,
        serviceIsCompleted,
        servicesAtSite,
        siteAddress,
        siteName,
      }
    },
  })
</script>
