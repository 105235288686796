<template>
  <NavigationSlot />
  <ErrorView>
    <RouterView v-slot="{ Component }">
      <Suspense timeout="0">
        <template #default>
          <Component :is="Component" />
        </template>
        <template #fallback>
          <LoadingView :is-loading="true" />
        </template>
      </Suspense>
    </RouterView>
  </ErrorView>
</template>

<script lang="ts">
  import { defineComponent, ref, watch } from 'vue'
  import { RouterView } from 'vue-router'
  import ErrorView from './views/ErrorView.vue'
  import NavigationSlot from './components/NavigationSlot.vue'
  import {
    fingerprintRequestInterceptor,
    graphQLErrorDetectorInterceptor,
    timezoneRequestInterceptor,
    tokenRequestInterceptor,
    tokenResponseInterceptor,
  } from './services/api/interceptors'
  import LoadingView from './views/LoadingView.vue'
  import { useAppStore } from './storage/app'
  import { initialiseFingerprint } from './services/fingerprint'
  import { restore } from './application'

  export default defineComponent({
    name: 'App',
    components: {
      ErrorView,
      LoadingView,
      NavigationSlot,
      RouterView,
    },

    setup() {
      const appStore = useAppStore()
      const tabFocus = ref(false)

      // token comes from index.html and is injected via cloudflare worker
      // eslint-disable-next-line no-undef
      appStore.token = token

      initialiseFingerprint()

      // initialize axios interceptors
      fingerprintRequestInterceptor()
      timezoneRequestInterceptor()
      tokenRequestInterceptor()
      tokenResponseInterceptor()
      graphQLErrorDetectorInterceptor()

      let inView = false
      const onWindowFocusChange = (e: Event) => {
        if ({ focus: 1, pageShow: 1 }[e.type]) {
          if (inView) {
            return
          }
          tabFocus.value = true
          inView = true
        } else if (inView) {
          tabFocus.value = !tabFocus.value
          inView = false
        }
      }

      window.addEventListener('focus', onWindowFocusChange)
      window.addEventListener('blur', onWindowFocusChange)
      window.addEventListener('pageshow', onWindowFocusChange)
      window.addEventListener('pagehide', onWindowFocusChange)

      watch(tabFocus, () => {
        restore()
      })
      restore()

      return {
        tabFocus,
      }
    },
  })
</script>

<style>
  .grecaptcha-badge {
    visibility: hidden;
  }
</style>
