<template>
  <div class="bg-verified-blue grid grid-cols-10 py-5">
    <div class="pl-2">
      <ChevronLeftIcon
        v-if="withHistory"
        class="h-6 text-white w-6"
        @click="routeTo"
      />
    </div>
    <div class="back-arrow col-span-8 text-center">
      <VerifiedLogo :height="logoHeight" :width="logoWidth" class="mb-1 mt-1" />
    </div>
    <div class="text-right z-10">
      <HamburgerMenu :with-menu="withMenu"></HamburgerMenu>
    </div>
    <div
      v-if="hasBanner()"
      class="flex fixed top-1.5 -right-8 rotate-30 p-0 truncate overflow-hidden leading-3"
    >
      <p
        class="m-0 px-4 pt-0.5 font-['Syncopate'] text-verified-xs w-24 text-verified-blue bg-white text-center flex-none"
      >
        {{ banner }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
  import { ChevronLeftIcon } from '@heroicons/vue/solid'
  import VerifiedLogo from './VerifiedLogo.vue'
  import { defineComponent, ref, watch } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import HamburgerMenu from './HamburgerMenu.vue'
  import config from '../config'

  export default defineComponent({
    name: 'NavigationSlot',
    components: { ChevronLeftIcon, HamburgerMenu, VerifiedLogo },

    setup() {
      const router = useRouter()
      const logoHeight = 30
      const logoWidth = 150
      const route = useRoute()
      const backRoute = ref('')
      const withHistory = ref(false)
      const withMenu = ref(false)
      const banner = ref(config.app.bannerText)

      function hasBanner() {
        return !!banner.value
      }

      function showBackArrow() {
        if (route.meta.back !== undefined) {
          withHistory.value = true
          backRoute.value = route.meta.back as string
        } else {
          withHistory.value = false
          backRoute.value = ''
        }
      }

      watch(
        () => route,
        () => {
          showBackArrow()
          withMenu.value = route?.meta?.showMenu as boolean
        },
        { deep: true }
      )

      function routeTo(): void {
        if (backRoute.value === '') {
          router.go(-1)
        }
        router.replace({ name: backRoute.value })
      }

      return {
        banner,
        hasBanner,
        logoHeight,
        logoWidth,
        routeTo,
        withHistory,
        withMenu,
      }
    },
  })
</script>
