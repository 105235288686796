import { useFingerprint } from '../storage/useFingerprint'

const isMacOsDesktopDevice = () =>
  navigator.vendor === 'Apple Computer, Inc.' && navigator.maxTouchPoints === 0

const isIPadDevice = () =>
  navigator.vendor === 'Apple Computer, Inc.' && navigator.maxTouchPoints > 0

const isIOS = () => {
  const store = useFingerprint()
  const operatingSystem = store.getOs()

  return operatingSystem === 'iOS' || isIPadDevice()
}

export { isIOS, isMacOsDesktopDevice }
