import 'es6-promise/auto'
import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import router from './router'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import config from './config'
import HoneybadgerVue from '@honeybadger-io/vue'
import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { refreshAuthLogic } from './services/api/csrf'
import pinia from './storage'
import { scanUuid } from './native'

axios.defaults.withCredentials = true
axios.defaults.baseURL = config.api.capture
createAuthRefreshInterceptor(axios, refreshAuthLogic, { statusCodes: [419] })

const app = createApp(App)
app.use(pinia)
app.use(VueReCaptcha, { siteKey: config.recaptcha.siteKey })
app.use(HoneybadgerVue, config.honeyBadger)

app.provide('config.google.apiKey', config.google.map.key)

app.use(router)

scanUuid()

app.mount('#app')
