import {
  NamedRoute,
  savePoint as savePointStore,
} from '../../storage/savePoint'
import { computed, ComputedRef } from 'vue'

export default function savePoint(): {
  getSavePoint: () => ComputedRef<NamedRoute>
  hasSavePoint: () => ComputedRef<boolean>
  resetSavePoint: () => void
  setSavePoint: (route: NamedRoute) => void
} {
  const store = savePointStore()

  function getSavePoint(): ComputedRef<NamedRoute> {
    return computed(() => store.savePoint)
  }

  function hasSavePoint(): ComputedRef<boolean> {
    return computed(() => store.savePoint.name !== '')
  }

  function resetSavePoint(): void {
    store.reset()
  }

  function setSavePoint(route: NamedRoute): void {
    store.savePoint = route
  }

  return {
    getSavePoint,
    hasSavePoint,
    resetSavePoint,
    setSavePoint,
  }
}
