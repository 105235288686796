const validate = (
  rules: string[],
  value: string | number | (string | number)[]
): boolean | string => {
  function parseRules(obj: string) {
    return Function('"use strict";return (' + obj + ')')()
  }
  const ruleExpressions = rules.map((rule) => parseRules(rule))

  try {
    return (
      ruleExpressions
        .map((rule) => rule(value))
        .find((v: boolean | string) => v !== true) || true
    )
  } catch (error) {
    if (error instanceof Error) {
      return error.toString()
    }

    return 'Unknown error occurred.'
  }
}
export default validate
