<template>
  <TitleBanner title="Please allow access to your Location" />
  <div class="flex items-center justify-center mt-10">
    <section class="h-80 ml-1 mr-2 w-1/5">
      <img
        id="titleIcon"
        :src="instructions.titleIcon"
        alt="icon"
        class="h-12 ml-auto mr-auto w-12"
      />
      <img
        id="contentIcon"
        :src="instructions.contentIcon"
        alt="icon"
        class="h-14 ml-auto mr-auto mt-9 w-16"
      />
    </section>
    <section id="instruction-details" class="h-80 w-7/12">
      <div class="h-5 mt-3">{{ instructions.title }}</div>
      <ol
        class="break-words leading-8 list-decimal list-inside mt-11 pr-2 text-base tracking-tight"
      >
        <li
          v-for="instruction in instructions.content"
          :key="instructions.content.indexOf(instruction)"
        >
          {{ instruction }}
        </li>
      </ol>
    </section>
  </div>
</template>

<script lang="ts">
  import TitleBanner from '../../components/TitleBanner.vue'
  import { defineComponent } from 'vue'
  import { instruction } from '../../config'
  import locationSettingsInstructions from '../../config/help/locationSettingsInstructions'
  import { isIOS } from '../../utilities/device'

  export default defineComponent({
    name: 'LocationHelp',
    components: { TitleBanner },

    setup() {
      const getInstructions = (): instruction => {
        return isIOS()
          ? locationSettingsInstructions.ios
          : locationSettingsInstructions.android
      }

      return { instructions: getInstructions() }
    },
  })
</script>
