import axios from 'axios'
import { useFingerprint } from '../../../storage/useFingerprint'

const fingerprintRequestInterceptor = (): void => {
  axios.interceptors.request.use(
    function (config) {
      const store = useFingerprint()

      if (config.headers === undefined) {
        config.headers = {
          'x-verified-fp-request-id': `${store.fingerprint?.requestId}`,
          'x-verified-fp-visitor-id': `${store.fingerprint?.visitorId}`,
        }
      } else {
        config.headers[
          'x-verified-fp-request-id'
        ] = `${store.fingerprint?.requestId}`
        config.headers[
          'x-verified-fp-visitor-id'
        ] = `${store.fingerprint?.visitorId}`
      }

      return Promise.resolve(config)
    },
    function (error) {
      return Promise.reject(error)
    }
  )
}

export default fingerprintRequestInterceptor
