<template>
  <div class="grid grid-cols-12">
    <section class="col-span-5">
      <CountryCodeSelector
        :default-country-code="defaultCountryCode"
        :disabled="disabled"
        class="country-code-selector"
        :classes="[{ 'rounded-r-none': true }]"
        @select-country="updateCountry"
      ></CountryCodeSelector>
    </section>
    <section class="col-span-7 pt-1">
      <BaseInput
        v-model:inputValue="phoneNumber"
        :input-classes="[
          { 'rounded-l-none': true },
          { border: true },
          { 'border-l-0': true },
        ]"
        :required="true"
        input-mode="numeric"
        identifier="phone-number-input"
        label="Phone Number"
        :is-valid="isValid"
        pattern="[0-9]*"
        :maxlength="maxLength"
        :disabled="disabled"
        @update:input-value="phoneNumberChanged"
        @key-pressed="allowNumericOnly"
      ></BaseInput>
    </section>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import BaseInput from './BaseInput.vue'
  import CountryCodeSelector from './CountryCodeSelector.vue'
  import useCountry from '../components/use/countries'
  import config, { country } from '../config'

  const { getCountryByInternationalPhoneNumber } = useCountry()

  export default defineComponent({
    name: 'PhoneNumberInput',
    components: { BaseInput, CountryCodeSelector },

    props: {
      defaultCountryCode: {
        type: String,
        default: 'AU',
      },

      disabled: {
        type: Boolean,
        default: false,
      },

      initialPhoneNumber: {
        type: String,
        default: '',
      },
    },

    emits: ['phoneNumberEntered', 'selectedCountry'],

    data() {
      return {
        internationalPhoneNumber: '',
        isValid: false,
        phoneNumber: '',
        previousPhoneNumberLength: 0,
        selectedCountry: config.countries.find(
          (country) => country.countryCode === this.defaultCountryCode
        ),
      }
    },

    computed: {
      maxLength(): number {
        let selectedCountry = config.countries.find(
          (country) => country.countryCode === this.selectedCountry?.countryCode
        )
        if (typeof selectedCountry === 'undefined') {
          return Number.POSITIVE_INFINITY
        }
        return selectedCountry.maxLength
      },
    },

    watch: {
      internationalPhoneNumber(): void {
        if (this.validatePhoneNumber(this.internationalPhoneNumber)) {
          this.isValid = true
          this.$emit('phoneNumberEntered', this.internationalPhoneNumber)
        } else {
          this.isValid = false
          this.$emit('phoneNumberEntered', '')
        }
      },

      selectedCountry(): void {
        this.phoneNumber = ''
      },
    },

    mounted() {
      if (this.initialPhoneNumber.substr(0, 1) === '+') {
        // determine the country this phone number belongs to
        this.selectedCountry = getCountryByInternationalPhoneNumber(
          this.initialPhoneNumber
        )?.value
        this.internationalPhoneNumber = this.initialPhoneNumber
        this.phoneNumber = this.convertToLocal(this.initialPhoneNumber)
      }
      this.phoneNumber =
        this.selectedCountry?.phoneNumberDisplayDecorator(
          this.phoneNumber.replace(/\s/g, '')
        ) || ''
    },

    methods: {
      allowNumericOnly(keyPressEvent: {
        key: string
        which: number
        returnValue: boolean
        target: { value: string }
      }): void {
        const key = keyPressEvent.key
        const regex = /[0-9]/
        if (!regex.test(key)) {
          keyPressEvent.returnValue = false
        }
      },

      cleanUpPhoneNumber(phoneNumber: string): void {
        let cleanPhoneNumber: string
        cleanPhoneNumber = this.removeLeadingZero(phoneNumber)
        cleanPhoneNumber = this.removeSpace(cleanPhoneNumber)
        if (this.selectedCountry) {
          this.internationalPhoneNumber =
            this.selectedCountry.countryCallingCode.concat(cleanPhoneNumber)
        }
      },

      convertToLocal(phoneNumber: string): string {
        if (
          this.selectedCountry?.countryCallingCode ===
          phoneNumber.substr(0, this.selectedCountry?.countryCallingCode.length)
        ) {
          return (
            '0' +
            phoneNumber.substr(this.selectedCountry?.countryCallingCode.length)
          )
        }

        return phoneNumber
      },

      phoneNumberChanged(phoneNumberEntered: string): void {
        this.cleanUpPhoneNumber(phoneNumberEntered)
        this.phoneNumber =
          this.selectedCountry?.phoneNumberDisplayDecorator(
            phoneNumberEntered.replace(/\s/g, '')
          ) || ''
      },

      removeLeadingZero(phoneNumber: string): string {
        if (phoneNumber.substring(0, 1) === '0') {
          return phoneNumber.substring(1)
        } else {
          return phoneNumber
        }
      },

      removeSpace(phoneNumber: string): string {
        return phoneNumber.replace(/\s/g, '')
      },

      updateCountry(country: country): void {
        this.selectedCountry = country
        this.$emit('selectedCountry', country)
      },

      validatePhoneNumber(phoneNumber: string): boolean {
        return this.selectedCountry?.validatePhoneNumber(phoneNumber) || false
      },
    },
  })
</script>
