<template>
  <div v-show="shouldShow" class="my-4" :class="identifier">
    <h4 class="my-2 pt-1" :class="{ 'text-red-500': !!errorMessage }">
      {{ content }}
    </h4>
    <div class="grid grid-cols-2">
      <div
        v-for="option in options"
        :key="options.indexOf(option)"
        class="gap-4 grid grid-cols-2 pb-2"
      >
        <div>
          <input
            :id="identifier + option?.label"
            v-model="picked"
            type="radio"
            :value="option?.value"
            :disabled="readonly"
          />
          <label
            :for="identifier + option?.label"
            class="pl-4"
            :class="{ 'text-red-500': !!errorMessage }"
          >
            {{ option?.label }}
          </label>
        </div>
      </div>
    </div>
    <span class="mt-1 mb-3 text-red-500">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, ref, watch } from 'vue'
  import { optionType } from '../../config/formOption'

  export default defineComponent({
    name: 'MultipleChoiceInput',

    props: {
      // eslint-disable-next-line vue/no-unused-properties
      conditions: {
        type: Array,
        default: () => undefined,
      },

      content: {
        type: String,
        default: 'question',
      },

      default: {
        type: Number,
        default: 0,
      },

      errorMessage: {
        type: String,
        default: '',
      },

      name: {
        type: String,
        default: '',
      },

      options: {
        type: Array as PropType<optionType[]>,
        default: () => [],
      },

      readonly: {
        type: Boolean,
        default: false,
      },

      show: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['formChanged'],

    setup(props, { emit }) {
      const picked = ref(props.default)
      const identifier = ref<string>(props.name)
      const shouldShow = ref(props.show)

      watch(picked, () => {
        emit('formChanged', picked.value)
      })

      return {
        identifier,
        picked,
        shouldShow,
      }
    },
  })
</script>
