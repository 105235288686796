import { computed, ComputedRef } from 'vue'
import overlap from './distance'
import config from '../../config'
import { useUserPosition } from '../../storage/userPosition'
import { useSite } from '../../storage/useSite'

export default function position(): {
  setUserPosition: () => Promise<void>
  getUserPosition: () => ComputedRef<Position>
  isUserOnSite: () => boolean
  generateNewPosition: () => Promise<GeolocationPosition>
  resetPosition: () => void
} {
  const store = useUserPosition()

  async function setUserPosition(): Promise<void> {
    try {
      const position = await generateNewPosition()
      store.position = {
        accuracy: position.coords.accuracy,
        altitude: position.coords.altitude,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      }
    } catch (error: unknown) {
      resetPosition()
      throw error
    }
  }

  function getUserPosition(): ComputedRef<Position> {
    return computed(() => store.position)
  }

  function isUserOnSite(): boolean {
    const userPosition = getUserPosition()
    const siteStore = useSite()
    const site = {
      latitude: siteStore.latitude,
      longitude: siteStore.longitude,
    }
    const user = {
      latitude: userPosition.value.latitude,
      longitude: userPosition.value.longitude,
    }
    return overlap(
      user,
      site,
      config.distance.radius.user,
      config.distance.radius.site
    )
  }

  function generateNewPosition() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        resolve,
        ({ code, message }) =>
          reject(
            Object.assign(new Error(message), {
              code: code,
              name: 'PositionError',
            })
          ),
        {
          enableHighAccuracy: true,
          maximumAge: 0, // 0 = disable cache to request new location each time
          timeout: 5000, // 5 seconds
        }
      )
    }) as Promise<GeolocationPosition>
  }

  function resetPosition() {
    store.reset()
  }

  return {
    generateNewPosition,
    getUserPosition,
    isUserOnSite,
    resetPosition,
    setUserPosition,
  }
}
