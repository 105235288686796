import { computed, ComputedRef } from 'vue'
import { useUserLogin } from '../../storage/userLogin'

export default function phoneNumber(): {
  getPhoneNumber: () => ComputedRef<string>
  setPhoneNumber: (phoneNumber: string) => void
} {
  const store = useUserLogin()

  function getPhoneNumber(): ComputedRef<string> {
    return computed(() => store.phoneNumber)
  }

  function setPhoneNumber(phoneNumber: string): void {
    store.phoneNumber = phoneNumber
  }

  return {
    getPhoneNumber,
    setPhoneNumber,
  }
}
