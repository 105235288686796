<template>
  <div class="relative">
    <TitleBanner :title="siteName" :sub-title="siteAddress" />
    <h2 class="my-2 pt-1 pb-4 text-center text-lg">
      {{ selectedService?.name }}
    </h2>
    <div class="ml-auto mr-auto mt-4 w-11/12 mb-20">
      <FormGenerator
        :schema="schema"
        :selected-frequency="selectedFrequency"
        @form-changed="validateFormData"
        @visibility-changed="updateVisibleComponents"
      />
      <button
        class="bg-blue-500 disabled:opacity-50 focus:bg-gray-400 hover:bg-gray-400 mt-6 p-3 rounded-sm text-white w-full"
        type="submit"
        :disabled="submitButtonIsDisabled"
        @click="submitForm"
      >
        Submit
      </button>
    </div>
    <LoadingView :is-loading="submitButtonIsDisabled" />
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import FormGenerator from '../components/form-components/FormGenerator.vue'
  import useSite from '../components/use/site'
  import useServices from '../components/use/services'
  import useFrequency from '../components/use/frequency'
  import useCustomer from '../components/use/customer'
  import { Answers, SchemaType } from '../config'
  import validate from '../components/use/formValidation'
  import {
    getServiceForm,
    storeServiceRecord,
  } from '../services/api/serviceForm'
  import { useRouter } from 'vue-router'
  import useUser from '../components/use/user'
  import dayjs from 'dayjs'
  import TitleBanner from '../components/TitleBanner.vue'
  import LoadingView from './LoadingView.vue'
  import useBypass from '../components/use/bypass'
  import usePosition from '../components/use/position'

  export default defineComponent({
    name: 'ServiceForm',

    components: { FormGenerator, LoadingView, TitleBanner },

    async setup() {
      const { getSiteAddress, getSiteName, getSiteId } = useSite()
      const { getSelectedService, setServiceCompleted } = useServices()
      const { getCustomerId } = useCustomer()
      const { getSelectedFrequency, setFreqCompletedBefore } = useFrequency()
      const { getUserPosition } = usePosition()
      const { getUserId, getUserSelectedContractorCompanyId } = useUser()
      const { getScanBypassed, getCheckinDate } = useBypass()
      const router = useRouter()
      const apiResponse = ref<string>('')
      const schema = ref<(SchemaType & { errorMessage: '' })[]>([])
      const siteName = getSiteName()
      const siteAddress = getSiteAddress()
      const selectedService = getSelectedService()
      const selectedFrequency = getSelectedFrequency()
      const customerId = getCustomerId()
      const answers = {} as Answers
      const userId = getUserId()
      const siteId = getSiteId()
      const contractorCompanyId = getUserSelectedContractorCompanyId()
      const [jobStartTime] = generateDateTime()
      const deviceInfo = navigator.userAgent
      const visibleComponents = ref<string[]>([])
      //TODO replace the static visitLogId once authentication token is done
      const visitLogId = '0'
      const submitButtonIsDisabled = ref(true)

      await fetchForm()

      async function fetchForm() {
        const response = await getServiceForm(
          customerId.value,
          selectedService.value.id,
          convertArrayToString(selectedFrequency.value)
        )
        apiResponse.value = response.original
        schema.value = response.validated as (SchemaType & {
          errorMessage: ''
        })[]
        schema.value.forEach((component) => {
          component.errorMessage = ''
          answers[component.name] = component.default || ''
        })
        submitButtonIsDisabled.value = false
      }

      function componentHasRules(change: {
        componentName: string
        value: string | string[]
      }): (SchemaType & { errorMessage: '' }) | undefined {
        const [component] = schema.value
          .filter(
            (component) =>
              component.name.toString() === change.componentName.toString()
          )
          .map((component) => {
            if (component.rules === undefined) {
              answers[change.componentName] = change.value
            } else {
              return component
            }
          })
        return component
      }

      function passErrorMessageInComponent(
        result: string | boolean,
        changedComponent: SchemaType
      ) {
        if (typeof result === 'boolean') {
          changedComponent.errorMessage = ''
        } else {
          changedComponent.errorMessage = result
        }
      }

      function validateFormData(change: {
        componentName: string
        value: string | string[]
      }) {
        answers[change.componentName] = change.value
        const changedComponent = componentHasRules(change)

        if (changedComponent !== undefined) {
          const result = validate(
            changedComponent.rules as string[],
            change.value
          )
          passErrorMessageInComponent(result, changedComponent)
        }
      }

      function generateDateTime() {
        const currentDateTime = dayjs().format('DD-MM-YYYY h:mm:ssA')
        const systemRemark =
          getScanBypassed().value && getCheckinDate().value !== ''
            ? getCheckinDate().value
            : dayjs().format('DD-MM-YYYY')
        return [currentDateTime, systemRemark]
      }
      function convertArrayToString(array: (string | number)[]) {
        return array.join('|')
      }

      function findQuestion(componentName: string) {
        let question = ''
        schema.value.forEach((component) => {
          if (component.name === componentName && 'question' in component) {
            question = component.question as string
          }
        })
        return question
      }

      function transformAnswers(answers: Answers) {
        let formData: {
          [key: string]: { question?: string; value: string }
        } = {}
        for (const [key, value] of Object.entries(answers)) {
          const question = findQuestion(key)
          let template: { question?: string; value: string } = {
            value: '',
          }
          if (question !== '') {
            template['question'] = question
          }
          Array.isArray(value)
            ? (template['value'] = convertArrayToString(value))
            : (template['value'] = value as string)

          formData[key] = template
        }
        return JSON.stringify(formData)
      }

      function findInvalidAnswers(schema: SchemaType[]) {
        return schema.filter(
          (component) =>
            visibleComponents.value.includes(component.name) &&
            component.errorMessage !== ''
        )
      }

      function checkFormAnswers(answers: Answers) {
        for (const [key, value] of Object.entries(answers)) {
          if (visibleComponents.value.includes(key)) {
            validateFormData({
              componentName: key,
              value: value as string | string[],
            })
          } else {
            delete answers[key]
          }
        }
        return findInvalidAnswers(schema.value)
      }

      function updateVisibleComponents(components: string[]) {
        visibleComponents.value = components
        visibleComponents.value.map((component) => {
          if (answers[component] === undefined) {
            answers[component] = ''
          }
        })
      }

      function setServiceCompletedOnFrequency(
        serviceId: string,
        subServiceId: string,
        frequencyIds: string[]
      ) {
        setServiceCompleted(serviceId, subServiceId)
        frequencyIds.forEach((frequencyId) => {
          setFreqCompletedBefore(serviceId, subServiceId, frequencyId)
        })
      }

      async function submitForm() {
        const invalidAnswers = checkFormAnswers(answers)
        if (invalidAnswers.length !== 0) {
          return
        }
        submitButtonIsDisabled.value = true
        const [jobEndTime, systemRemark] = generateDateTime()
        const position = getUserPosition()
        const formData = transformAnswers(answers)

        const response = await storeServiceRecord(
          deviceInfo,
          jobStartTime,
          jobEndTime,
          userId.value.toString(),
          customerId.value,
          siteId.value.toString(),
          selectedService.value.id,
          selectedService.value.subServiceId,
          convertArrayToString(selectedFrequency.value),
          systemRemark,
          visitLogId,
          contractorCompanyId.value,
          position.value.latitude,
          position.value.longitude,
          position.value.altitude,
          position.value.accuracy,
          formData,
          apiResponse.value
        )
        setServiceCompletedOnFrequency(
          selectedService.value.id,
          selectedService.value.subServiceId,
          selectedFrequency.value
        )
        submitButtonIsDisabled.value = false

        await router.replace({
          name: 'serviceCheckout',
          params: { confirmationCode: response },
        })
      }

      return {
        router,
        schema,
        selectedFrequency,
        selectedService,
        siteAddress,
        siteName,
        submitButtonIsDisabled,
        submitForm,
        updateVisibleComponents,
        validateFormData,
      }
    },
  })
</script>
