<template>
  <div class="relative">
    <TitleBanner title="Forgot PIN" />
    <div class="p-4">
      <section class="mt-7">
        <PhoneNumberInput
          :initial-phone-number="phoneNumber"
          :default-country-code="countryCode"
          :disabled="true"
        />
      </section>
      <section class="flex pt-3 space-x-4">
        <BaseButton
          action="Cancel"
          identifier="cancel-sending-sms"
          type="button"
          @click="backToLogin"
        ></BaseButton>
        <BaseButton
          action="Send SMS"
          identifier="send-sms-button"
          type="submit"
          :disabled="smsBtnDisabled"
          @click="sendSMS"
        ></BaseButton>
      </section>
      <section v-if="pinSent">
        <BaseDialogue
          right-button-label="Back to Login"
          :right-button-shown="true"
          button-type="button"
          identifier="forgot-pin-dialogue"
          right-btn-route="/login"
        >
          Your pin has been sent in a SMS
        </BaseDialogue>
      </section>
    </div>
    <LoadingView :is-loading="smsBtnDisabled" />
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import TitleBanner from '../components/TitleBanner.vue'
  import BaseButton from '../components/BaseButton.vue'
  import PhoneNumberInput from '../components/PhoneNumberInput.vue'
  import BaseDialogue from '../components/BaseDialogue.vue'
  import { pinReset } from '../services/api/user'
  import useCountry from '../components/use/countries'
  import usePhoneNumber from '../components/use/phoneNumber'
  import LoadingView from './LoadingView.vue'

  export default defineComponent({
    name: 'ForgotPin',
    components: {
      BaseButton,
      BaseDialogue,
      LoadingView,
      PhoneNumberInput,
      TitleBanner,
    },

    setup() {
      const { getCountryByInternationalPhoneNumber } = useCountry()
      const { getPhoneNumber } = usePhoneNumber()

      const phoneNumber = getPhoneNumber()
      const countryCode = getCountryByInternationalPhoneNumber(
        phoneNumber.value
      )?.value.countryCode
      const pinSent = ref(false)
      const smsBtnDisabled = ref(true)

      async function sendSMS() {
        smsBtnDisabled.value = true
        const response = await pinReset(phoneNumber.value)
        pinSent.value = response.code === 0
        smsBtnDisabled.value = false
      }

      return {
        countryCode,
        phoneNumber,
        pinSent,
        sendSMS,
        smsBtnDisabled,
      }
    },

    mounted() {
      this.recaptcha()
    },

    methods: {
      backToLogin() {
        this.$router.push({ name: 'login' })
      },

      async recaptcha() {
        try {
          await this.$recaptchaLoaded()
          await this.$recaptcha('sendSMS').then(() => {
            this.smsBtnDisabled = false
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          return error.message
        }
      },
    },
  })
</script>
