import { defineStore } from 'pinia'
import { ref } from 'vue'
import { ContractorCompany } from '../services/api/user'

export const useUser = defineStore(
  'user',
  () => {
    const contractorCompanies = ref([
      { id: 0, name: '' },
    ] as ContractorCompany[])
    const contractorEmployeeId = ref(0)
    const firstName = ref('there')
    const id = ref(0)
    const identityIsValid = ref(false)
    const lastname = ref('')
    const name = ref('')
    const phone = ref('')
    const selectedContractorCompanyId = ref(0)
    const timezone = ref('')

    const setContractorCompanies = (companies: ContractorCompany[]) => {
      contractorCompanies.value[0] &&
        contractorCompanies.value[0].id === 0 &&
        contractorCompanies.value.shift()

      companies.forEach((company: ContractorCompany) => {
        const companyExists = contractorCompanies.value.find(
          (contractorCompany: ContractorCompany) =>
            contractorCompany.id.toString() === company.id.toString()
        )
        if (!companyExists) {
          contractorCompanies.value.push(company)
        }
      })
    }

    const reset = () => {
      contractorCompanies.value = [{ id: 0, name: '' }]
      contractorEmployeeId.value = 0
      firstName.value = ''
      id.value = 0
      identityIsValid.value = false
      lastname.value = ''
      name.value = ''
      phone.value = ''
      selectedContractorCompanyId.value = 0
      timezone.value = ''
    }

    return {
      contractorCompanies,
      contractorEmployeeId,
      firstName,
      id,
      identityIsValid,
      lastname,
      name,
      phone,
      reset,
      selectedContractorCompanyId,
      setContractorCompanies,
      timezone,
    }
  },
  {
    persist: true,
  }
)
