<template>
  <TemporaryMessageBanner
    :show="showBanner"
    message="You have been successfully checked out"
    banner-classes="bg-green-400"
  />
  <TitleBanner
    title="Thanks for using Verified"
    sub-title="You have successfully logged out"
  ></TitleBanner>
</template>

<script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue'
  import TitleBanner from '../components/TitleBanner.vue'
  import TemporaryMessageBanner from '../components/NotificationBanner.vue'
  import { useRouter } from 'vue-router'
  import resetStore from '../components/use/resetStore'

  export default defineComponent({
    name: 'ThankYouPage',
    components: { TemporaryMessageBanner, TitleBanner },

    setup() {
      const router = useRouter()
      const showBanner = ref(true)
      resetStore()

      onMounted(() => {
        setTimeout(() => {
          showBanner.value = false
        }, 2500)

        setTimeout(() => {
          router.push({ name: 'home' })
        }, 7000)
      })

      return {
        showBanner,
      }
    },
  })
</script>
