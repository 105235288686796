import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import { PiniaSharedState } from 'pinia-shared-state'

const pinia = createPinia()

pinia.use(piniaPluginPersistedState)
pinia.use(
  PiniaSharedState({
    enable: true,
    initialize: true,
    type: 'localstorage',
  })
)

export default pinia
