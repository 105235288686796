import axios from 'axios'
import config from '../../../config'
import resetStore from '../../../components/use/resetStore'

const graphQLErrorDetectorInterceptor = (): void => {
  axios.interceptors.response.use(function (response) {
    if (response.data?.errors?.[0]?.message === 'Unauthenticated.') {
      resetStore()
      window.location.href = config.unauthorized.redirect.url
    }

    if (response.data.errors) {
      throw new Error(response.data.errors?.[0].message)
    }

    return response
  })
}

export default graphQLErrorDetectorInterceptor
