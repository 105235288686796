import axios from 'axios'
import { useUser } from '../../../storage/user'

const timezoneRequestInterceptor = (): void => {
  axios.interceptors.request.use(
    function (config) {
      const store = useUser()

      if (config.headers === undefined) {
        config.headers = {
          'x-verified-timezone': `${store.timezone}`,
        }
      } else {
        config.headers['x-verified-timezone'] = `${store.timezone}`
      }

      return Promise.resolve(config)
    },
    function (error) {
      return Promise.reject(error)
    }
  )
}

export default timezoneRequestInterceptor
