<template>
  <div>
    <label
      for="location"
      :class="[labelClasses]"
      class="block font-medium text-gray-700"
    >
      {{ label }}
    </label>
    <select
      id="location"
      v-model="selected"
      name="location"
      class="block border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 mt-1 pl-3 pr-10 py-2 rounded-md sm:text-sm text-base w-full"
      :disabled="disabled"
    >
      <option selected>
        {{ defaultOption }}
      </option>
      <option v-for="option in options" :key="option.id" :value="option.id">
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, ref, watch } from 'vue'
  import { SelectOption } from '../config'

  export default defineComponent({
    name: 'BaseSelector',

    props: {
      defaultOption: {
        type: String,
        default: '',
      },

      disabled: {
        type: Boolean,
        default: false,
      },

      label: {
        type: String,
        default: '',
      },

      labelClasses: {
        type: Array,
        default: () => [],
      },

      options: {
        type: Array as PropType<SelectOption[]>,
        default: () => [],
      },
    },

    emits: ['optionChanged'],

    setup(props, { emit }) {
      const selected = ref<string | number>(props.defaultOption)

      watch(selected, () => {
        emit('optionChanged', selected.value)
      })

      return {
        selected,
      }
    },
  })
</script>
