import UserHistory from '../views/menu/UserHistory.vue'

export default {
  component: UserHistory,
  meta: {
    back: '',
    showMenu: true,
  },
  name: 'history',
  path: '/history',
}
