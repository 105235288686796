<template>
  <Transition name="fade">
    <div
      v-if="show"
      class="w-full py-5 inline-flex items-center justify-center"
      :class="bannerClasses"
    >
      <span class="p-1 w-8 h-8">
        <CheckCircleIcon />
      </span>
      <span>{{ message }}</span>
    </div>
  </Transition>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { CheckCircleIcon } from '@heroicons/vue/outline'

  export default defineComponent({
    name: 'NotificationBanner',
    components: { CheckCircleIcon },
    props: {
      bannerClasses: {
        type: String,
        default: '',
      },

      message: {
        type: String,
        default: 'default message',
      },

      show: {
        type: Boolean,
        default: false,
      },
    },
  })
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
