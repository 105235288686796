import { computed, ComputedRef } from 'vue'
import { ContractorCompany } from '../../services/api/user'
import { useUser } from '../../storage/user'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import dayjs from 'dayjs'
dayjs.extend(utc)
dayjs.extend(timezone)

export default function user(): {
  getFirstName: () => ComputedRef<string>
  getUserAuthenticationResult: () => ComputedRef<boolean>
  getUserId: () => ComputedRef<number>
  getUserSelectedContractorCompanyId: () => ComputedRef<number>
  getContractorEmployeeId: () => ComputedRef<number>
  getContractorCompanies: () => ComputedRef<ContractorCompany[]>
  hasSelectedContractorCompany: () => ComputedRef<boolean>
  isLoggedIn: () => ComputedRef<boolean>
  setUserAuthenticationResult: (authentication: boolean) => void
  setSelectedContractorCompanyId: (id: number) => void
  setTimezone: () => void
  setUserFirstName: (firstName: string) => void
  setUserId: (id: number) => void
  setUserPhoneNumber: (phoneNumber: string) => void
  setUserContractorEmployeeId: (id: number) => void
  setUserContractorCompanies: (contractorCompanies: ContractorCompany[]) => void
  resetUserStore: () => void
} {
  const store = useUser()

  function getContractorEmployeeId(): ComputedRef<number> {
    return computed(() => store.contractorEmployeeId)
  }

  function getUserId(): ComputedRef<number> {
    return computed(() => store.id)
  }

  function getUserSelectedContractorCompanyId(): ComputedRef<number> {
    return computed(() => store.selectedContractorCompanyId)
  }

  function getFirstName(): ComputedRef<string> {
    return computed(() => store.firstName)
  }

  function getUserAuthenticationResult(): ComputedRef<boolean> {
    return computed(() => store.identityIsValid)
  }

  function getContractorCompanies(): ComputedRef<ContractorCompany[]> {
    return computed(() => store.contractorCompanies)
  }

  function hasSelectedContractorCompany(): ComputedRef<boolean> {
    return computed(() => getUserSelectedContractorCompanyId().value > 0)
  }

  function isLoggedIn(): ComputedRef<boolean> {
    return computed(() => getUserId().value > 0)
  }

  function setUserAuthenticationResult(authentication: boolean): void {
    store.identityIsValid = authentication
  }

  function setSelectedContractorCompanyId(id: number) {
    store.selectedContractorCompanyId = id
  }

  function setTimezone(): void {
    store.timezone = dayjs.tz.guess()
  }

  function setUserFirstName(firstName: string) {
    store.firstName = firstName
  }

  function setUserId(id: number) {
    store.id = id
  }

  function setUserPhoneNumber(phone: string) {
    store.phone = phone
  }

  function setUserContractorCompanies(
    contractorCompanies: ContractorCompany[]
  ) {
    store.setContractorCompanies(contractorCompanies)
  }

  function setUserContractorEmployeeId(id: number) {
    store.contractorEmployeeId = id
  }

  function resetUserStore(): void {
    store.reset()
  }

  return {
    getContractorCompanies,
    getContractorEmployeeId,
    getFirstName,
    getUserAuthenticationResult,
    getUserId,
    getUserSelectedContractorCompanyId,
    hasSelectedContractorCompany,
    isLoggedIn,
    resetUserStore,
    setSelectedContractorCompanyId,
    setTimezone,
    setUserAuthenticationResult,
    setUserContractorCompanies,
    setUserContractorEmployeeId,
    setUserFirstName,
    setUserId,
    setUserPhoneNumber,
  }
}
