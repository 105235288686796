import { computed, ComputedRef } from 'vue'
import { useBypass } from '../../storage/useBypass'
import { BypassReasonContract } from '../../services/api/BypassReason'

export default function bypass(): {
  getBypass: () => Bypass | null
  getCheckinDate: () => ComputedRef<string>
  resetBypass: () => void
  setReasonDescription: (description: string) => void
  getSelectedBypassReason: () => ComputedRef<BypassReasonContract>
  setCheckinDate: (checkinDate: string) => void
  setScanBypassed: (bypassed: boolean) => void
  setSelectedBypassReason: (bypassReason: BypassReasonContract) => void
  getReasonDescription: () => ComputedRef<string>
  getScanBypassed: () => ComputedRef<boolean>
} {
  const store = useBypass()

  function getBypass(): Bypass | null {
    if (getScanBypassed().value) {
      const reasonDescription = getReasonDescription().value

      return {
        checkinDate: getCheckinDate().value || null,
        reason:
          (reasonDescription !== '' && reasonDescription) ||
          getSelectedBypassReason().value.name,
      }
    }

    return null
  }

  function getSelectedBypassReason(): ComputedRef<BypassReasonContract> {
    return computed(() => store.selectedBypassReason)
  }

  function getCheckinDate(): ComputedRef<string> {
    return computed(() => store.checkinDate)
  }

  function getReasonDescription(): ComputedRef<string> {
    return computed(() => store.reasonDescription)
  }

  function getScanBypassed(): ComputedRef<boolean> {
    return computed(() => store.scanBypassed)
  }

  function setSelectedBypassReason(bypassReason: BypassReasonContract): void {
    store.selectedBypassReason = bypassReason
  }

  function setCheckinDate(checkinDate: string): void {
    store.checkinDate = checkinDate
  }

  function setReasonDescription(description: string): void {
    store.reasonDescription = description
  }

  function setScanBypassed(bypassed: boolean): void {
    store.scanBypassed = bypassed
  }

  function resetBypass(): void {
    store.reset()
  }

  return {
    getBypass,
    getCheckinDate,
    getReasonDescription,
    getScanBypassed,
    getSelectedBypassReason,
    resetBypass,
    setCheckinDate,
    setReasonDescription,
    setScanBypassed,
    setSelectedBypassReason,
  }
}
