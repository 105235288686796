export default `
  mutation(
    $serviceId: String!,
    $customerId: String!,
    $siteId: String!,
    $subServiceId: String!,
    $userId: String!
    $inspectionPhoto: String,
    $defectPhoto: String,
    $sequence: String!
    ){
    storePhoto(
        serviceId: $serviceId,
        customerId: $customerId,
        siteId: $siteId,
        subServiceId: $subServiceId,
        userId: $userId,
        inspectionPhoto: $inspectionPhoto,
        defectPhoto: $defectPhoto,
        sequence: $sequence
    ){
        photoId
    }
}
`
