import axios from 'axios'
import query from './../../graphql/queries/queryUserByPhone'
import mutation from '../../graphql/mutations/PinReset'
import authenticateUserMutation from '../../graphql/mutations/authenticateUser'
import decodeToPromise from './typeChecker'
import * as io from 'io-ts'
import changePinMutation from '../../graphql/mutations/changePin'

export type ContractorCompany = io.TypeOf<typeof contractorCompanyTypeChecker>

export type ContractorEmployee = io.TypeOf<typeof contractorEmployeeTypeChecker>

export type User = io.TypeOf<typeof userTypeChecker>

export type messageResponse = io.TypeOf<typeof messageResponseTypeChecker>

export type AuthenticatedUser = io.TypeOf<typeof authenticatedUserTypeChecker>

const contractorCompanyTypeChecker = io.type({
  id: io.number,
  name: io.string,
})

const contractorEmployeeTypeChecker = io.type({
  id: io.number,
})

const userTypeChecker = io.type({
  firstname: io.string,
  id: io.number,
  isVisitor: io.boolean,
  lastname: io.string,
  name: io.string,
  phone: io.string,
})

const messageResponseTypeChecker = io.type({
  code: io.number,
  message: io.string,
})

const authenticatedUserTypeChecker = io.type({
  contractorCompanies: io.array(contractorCompanyTypeChecker),
  contractorEmployee: contractorEmployeeTypeChecker,
  firstname: io.string,
  id: io.number,
  lastname: io.string,
  name: io.string,
  phone: io.string,
})

const fetchUserByPhone = async (phone: string): Promise<User> => {
  return await axios
    .get('/graphql', {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        query,
        variables: {
          phone: phone,
        },
      },
    })
    .then((response) => response?.data?.data?.user)
    .then(async (data) => {
      data.id = parseInt(data.id)
      return await decodeToPromise(userTypeChecker, data)
    })
}

export default fetchUserByPhone

export const pinReset = async (phone: string): Promise<messageResponse> => {
  return await axios
    .post(
      '/graphql',
      {
        query: mutation,
        variables: { phone: phone },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then((response) => response?.data?.data?.resetPin)
    .then(async (data) => {
      return await decodeToPromise(messageResponseTypeChecker, data)
    })
}

export const authenticateUser = (
  phone: string,
  pin: string
): Promise<AuthenticatedUser> => {
  return axios
    .post(
      '/graphql',
      {
        query: authenticateUserMutation,
        variables: { phone: phone, pin: pin },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then((response) => response?.data?.data?.authenticateUser)
    .then(async (data) => {
      data.id = parseInt(data.id)

      return await decodeToPromise(authenticatedUserTypeChecker, data)
    })
}

export const changePin = async (
  userId: string,
  newPin: string
): Promise<messageResponse> => {
  const response = await axios.post(
    '/graphql',
    {
      query: changePinMutation,
      variables: {
        pin: newPin,
        userId: userId,
      },
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return await decodeToPromise(
    messageResponseTypeChecker,
    response?.data?.data?.changePin
  )
}
