<template>
  <div>
    <div class="p-4">
      <TitleBanner title="My History" />
    </div>
    <div class="px-4">
      <BaseSearchBar
        :placeholder="!serviceDate ? 'Search by site or service' : ''"
        @search="searchQueryChanged"
      ></BaseSearchBar>
    </div>
    <div class="px-4">
      <div class="flow-root mt-6">
        <ul class="-my-5 divide-gray-200 divide-y">
          <li
            v-for="serviceRecord in serviceRecords"
            :key="serviceRecord.id"
            class="py-4"
          >
            <div class="grid grid-cols-5 gap-4">
              <div class="border-r-2">
                {{ serviceRecord.date }}
              </div>
              <div class="col-span-4">
                <p class="truncate">
                  Conf No:
                  <span class="font-bold">{{ serviceRecord.id }}</span>
                </p>
                <p class="truncate">
                  {{ serviceRecord.siteName }}
                </p>
                <p class="text-sm truncate">
                  {{ serviceRecord.serviceName }}
                  {{
                    serviceRecord.frequencyCodes
                      ? serviceRecord.frequencyCodes.join(' ')
                      : ''
                  }}
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div v-if="loading" class="pt-3 text-center">
          <span class="animate-spin-slow w-12 h-12 block mx-auto">
            <img
              id="contentIcon"
              src="../../assets/black-spinner-transparent.png"
              class="text-blue-500 h-full w-full"
              alt="icon"
            />
          </span>
          <span>Loading...</span>
        </div>
        <div v-if="noMore" class="py-3 text-center">
          There are no more service records.
        </div>
        <div v-if="noResult" class="py-3 text-center">
          There are no available service records.
        </div>
      </div>
    </div>
    <div
      class="rounded-full h-16 w-16 flex items-center justify-center bg-blue-500 text-white fixed bottom-4 right-6"
      @click="calendarShown = !calendarShown"
    >
      <CalendarIcon class="h-6 w-6" />
    </div>
    <div
      v-if="calendarShown"
      class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-300"
    >
      <DatePicker
        v-model="serviceDate"
        :max-date="new Date()"
        :model-config="modelConfig"
      />
    </div>
    <div v-if="serviceDate" class="absolute top-40 right-14 mt-1.5">
      <button
        type="button"
        class="flex items-center bg-indigo-100 hover:bg-indigo-200 text-sm text-blue-600 font-semibold h-8 px-2 m-1 rounded-lg border-2 border-transparent focus:border-indigo-600 focus:outline-none"
      >
        {{ serviceDate }}
        <XIcon
          class="w-4 h-4 text-gray-600 hover:text-indigo-600 ml-1 -mr-1"
          @click.stop="removeDate"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
  import TitleBanner from '../../components/TitleBanner.vue'
  import BaseSearchBar from '../../components/BaseSearchBar.vue'
  import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue'
  import { DatePicker } from 'v-calendar'
  import 'v-calendar/dist/style.css'
  import useUser from '../../components/use/user'
  import {
    controller as abortController,
    getServiceRecordByUser,
    ServiceRecord,
  } from '../../services/api/ServiceRecord'
  import useScroll from '../../components/use/scroll'
  import { CalendarIcon, XIcon } from '@heroicons/vue/outline'

  export default defineComponent({
    name: 'UserHistory',
    components: {
      BaseSearchBar,
      CalendarIcon,
      DatePicker,
      TitleBanner,
      XIcon,
    },

    async setup() {
      const { getUserId } = useUser()
      const { addScrollListener, removeScrollListener } = useScroll(
        loadMoreServiceRecords
      )
      const calendarShown = ref(false)
      const serviceDate = ref(null)
      const serviceRecords = ref<ServiceRecord[]>([])
      const loading = ref(false)
      const noMore = ref(false)
      const noResult = ref(false)
      const searchQuery = ref<string | null>(null)
      let page = 1

      const modelConfig = {
        mask: 'DD-MM-YYYY',
        type: 'string',
      }

      onMounted(() => {
        addScrollListener()
      })

      onUnmounted(() => {
        removeScrollListener()
      })

      watch(serviceDate, () => {
        dateSelected()
      })

      await loadServiceRecords(page)

      async function loadServiceRecords(page: number): Promise<void> {
        loading.value = true
        noMore.value = false
        noResult.value = false
        const response = await getServiceRecordByUser(
          getUserId().value,
          page.toString(),
          searchQuery.value,
          serviceDate.value
        )
        if (response) {
          response.length > 0 && serviceRecords.value.push(...response)
          manageLoadingState(response)
        }
      }

      function manageLoadingState(data: ServiceRecord[]) {
        if (data.length === 0 && serviceRecords.value.length !== 0) {
          noMore.value = true
        } else if (data.length === 0 && serviceRecords.value.length === 0) {
          noResult.value = true
        }
        loading.value = false
      }

      async function searchQueryChanged(search: string): Promise<void> {
        prepareForFiltering()
        searchQuery.value = search ? search : null
        await loadServiceRecords(page)
      }

      async function dateSelected(): Promise<void> {
        prepareForFiltering()
        calendarShown.value = false
        await loadServiceRecords(page)
      }

      function prepareForFiltering(): void {
        abortController.abort()
        page = 1
        serviceRecords.value = []
      }

      async function loadMoreServiceRecords(): Promise<void> {
        if (!loading.value && !noMore.value && !noResult.value) {
          page++
          await loadServiceRecords(page)
        }
      }

      function removeDate(): void {
        serviceDate.value = null
      }

      return {
        calendarShown,
        loadMoreServiceRecords,
        loading,
        modelConfig,
        noMore,
        noResult,
        removeDate,
        searchQueryChanged,
        serviceDate,
        serviceRecords,
      }
    },
  })
</script>
