import ServiceForm from '../views/ServiceForm.vue'

export default {
  component: ServiceForm,
  meta: {
    back: '',
    showMenu: true,
  },
  name: 'serviceForm',
  path: '/services/form',
}
