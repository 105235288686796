<template>
  <div v-show="withMenu">
    <MenuIcon class="hamburger-menu h-6 text-white w-6" @click="toggleMenu" />
    <Transition name="fade">
      <div
        v-if="show"
        class="fixed flex flex-row-reverse h-screen w-full top-0 left-0 bg-gray-400 bg-opacity-60 z-10"
      >
        <div class="w-8/12 bg-white">
          <ul class="p-3">
            <li
              id="my-history"
              class="py-4 inline-flex items-center w-full"
              @click="menuOptionClicked('history')"
            >
              <ClockIcon class="w-6 h-6 mr-4 text-gray-700" />
              <span>My History</span>
            </li>
            <li
              v-if="canCheckout"
              id="checkout"
              class="py-4 inline-flex items-center w-full"
              @click="menuOptionClicked('checkout')"
            >
              <LogoutIcon class="w-7 h-6 mr-3 text-gray-700" />
              <span>Checkout</span>
            </li>
            <li
              id="change-pin"
              class="py-4 inline-flex items-center w-full"
              @click="menuOptionClicked('changePin')"
            >
              <PencilAltIcon class="w-6 h-6 mr-4 text-gray-500" />
              <span>Change PIN</span>
            </li>
            <li
              id="help"
              class="py-4 inline-flex items-center w-full"
              @click="menuOptionClicked('help')"
            >
              <QuestionMarkCircleIcon class="w-6 h-6 mr-4 text-gray-500" />
              <span>Help</span>
            </li>
            <li
              id="terms-and-conditions"
              class="mt-6 text-left underline text-blue-700 text-sm"
            >
              <a
                href="https://webcontent.verified.com.au/Terms-and-conditions/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Terms & Conditions
              </a>
            </li>
            <li
              id="privacy-policy"
              class="mt-2 text-left underline text-blue-700 text-sm"
            >
              <a
                href="https://webcontent.verified.com.au/privacy-policy/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
        <div class="w-4/12 opacity-100" @click="show = false"></div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import { MenuIcon, QuestionMarkCircleIcon } from '@heroicons/vue/solid'
  import { ClockIcon, LogoutIcon, PencilAltIcon } from '@heroicons/vue/outline'
  import useUser from '../components/use/user'
  import useSite from '../components/use/site'
  import { useRoute, useRouter } from 'vue-router'

  export default defineComponent({
    name: 'HamburgerMenu',
    components: {
      ClockIcon,
      LogoutIcon,
      MenuIcon,
      PencilAltIcon,
      QuestionMarkCircleIcon,
    },

    props: {
      withMenu: {
        type: Boolean,
        default: false,
      },
    },

    setup() {
      const show = ref<boolean>(false)
      const { getUserAuthenticationResult, hasSelectedContractorCompany } =
        useUser()
      const router = useRouter()
      const { getSiteSelectionConfirmed } = useSite()
      const canCheckout =
        getUserAuthenticationResult() &&
        getSiteSelectionConfirmed() &&
        hasSelectedContractorCompany()
      const route = useRoute()
      const menuItemsRouteName = ['changePin', 'checkout', 'help', 'history']

      function toggleMenu() {
        show.value = !show.value
      }

      function menuOptionClicked(routeName: string): void {
        const lastPage =
          route.name !== undefined && route.name !== null ? route.name : ''

        if (menuItemsRouteName.includes(lastPage as string)) {
          router.replace({ name: routeName })
        } else {
          router.push({ name: routeName })
        }
        toggleMenu()
      }

      return {
        canCheckout,
        menuOptionClicked,
        router,
        show,
        toggleMenu,
      }
    },
  })
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
