import FingerprintJS, {
  ExtendedGetResult,
} from '@fingerprintjs/fingerprintjs-pro'
import config from '../../config'
import { useFingerprint } from '../../storage/useFingerprint'

export const initialiseFingerprint = () => {
  const store = useFingerprint()

  if (!store.hasFingerprint()) {
    FingerprintJS.load({
      apiKey: config.fingerprint.token,
      endpoint: config.fingerprint.endpoint,
    })
      .then(
        (fp): Promise<ExtendedGetResult> => fp.get({ extendedResult: true })
      )
      .then((result) => {
        store.fingerprint = result

        return result
      })
      .catch(() => {
        store.reset()
        throw new Error(
          'Error occurred trying to initialise application. Please try again later.'
        )
      })
  }
}
