import ContractorCompanySelection from '../views/ContractorCompanySelection.vue'

export default {
  component: ContractorCompanySelection,
  meta: {
    back: 'login',
    showMenu: true,
  },
  name: 'contractorCompanySelection',
  path: '/contractorCompanySelection',
}
