<template>
  <div v-show="shouldShow" :class="identifier" class="my-4">
    <span class="inline-block" :class="{ 'text-red-500': !!errorMessage }">
      {{ content || 'Signature' }}
    </span>
    <canvas
      ref="signaturePad"
      class="border border-solid border-gray-400 w-10/12 h-36 rounded-md mt-2"
      @touchstart="touchStart"
      @touchmove="touchMove"
      @touchend="touchEnd"
    ></canvas>
    <div class="w-10/12">
      <button
        type="button"
        class="border p-2 rounded-sm"
        @click="clearSignature"
      >
        Clear Signature
      </button>
    </div>
    <span class="mt-1 mb-1 text-red-500">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, ref, watch } from 'vue'

  export default defineComponent({
    name: 'SignatureInput',

    props: {
      // eslint-disable-next-line vue/no-unused-properties
      conditions: {
        type: Array,
        default: () => undefined,
      },

      content: {
        type: String,
        default: '',
      },

      errorMessage: {
        type: String,
        default: '',
      },

      name: {
        type: String,
        default: '',
      },

      show: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['formChanged'],

    setup(props, { emit }) {
      const signaturePad = ref<HTMLCanvasElement | null>(null)
      const identifier = ref<string>(props.name)
      const signatureInput = ref<string>('')
      const blankCanvasDataUrl = ref<string>('')
      const isDrawing = ref<boolean>(false)
      const shouldShow = ref(props.show)
      let canvas: HTMLCanvasElement | null = null
      let canvasContext: CanvasRenderingContext2D | null = null
      let coordinateX = 0
      let coordinateY = 0

      onMounted(() => {
        getCanvasContext()
        blankCanvasDataUrl.value = canvas?.toDataURL() || ''
      })

      watch(signatureInput, (newSignature, prevSignature) => {
        if (prevSignature !== newSignature) {
          emit('formChanged', signatureInput.value)
        }
      })

      function getCanvasContext() {
        canvas = signaturePad.value
        if (canvas !== null) {
          canvasContext = canvas.getContext('2d')
        }
      }

      function calculateOffset(evt: TouchEvent) {
        const touchOnCanvas = evt.touches[0].target as HTMLCanvasElement

        const offsetX = evt.touches[0].pageX - touchOnCanvas.offsetLeft
        const offsetY = evt.touches[0].pageY - touchOnCanvas.offsetTop

        return [offsetX, offsetY]
      }

      function draw(x1: number, y1: number, x2: number, y2: number) {
        const context = canvasContext

        if (context !== null) {
          context.beginPath()
          context.strokeStyle = 'black'
          context.lineWidth = 1
          context.moveTo(x1, y1)
          context.lineTo(x2, y2)
          context.stroke()
          context.closePath()
        }
      }

      function touchStart(evt: TouchEvent) {
        isDrawing.value = true

        const [offsetX, offsetY] = calculateOffset(evt)
        coordinateX = offsetX
        coordinateY = offsetY
      }

      function touchMove(evt: TouchEvent) {
        if (!isDrawing.value) {
          return
        }

        evt.preventDefault()
        const [offsetX, offsetY] = calculateOffset(evt)
        draw(coordinateX, coordinateY, offsetX, offsetY)
        coordinateX = offsetX
        coordinateY = offsetY
      }

      function touchEnd() {
        const canvasDataUrl = canvas?.toDataURL() || ''
        if (canvasDataUrl !== blankCanvasDataUrl.value) {
          signatureInput.value = canvasDataUrl
        }
        coordinateX = 0
        coordinateY = 0
        isDrawing.value = false
      }

      function clearSignature() {
        if (canvas === null || canvasContext === null) {
          return
        }

        canvasContext.clearRect(0, 0, canvas.width, canvas.height)
        isDrawing.value = false
        signatureInput.value = ''
      }

      return {
        clearSignature,
        identifier,
        shouldShow,
        signaturePad,
        touchEnd,
        touchMove,
        touchStart,
      }
    },
  })
</script>
