import UserLogin from '../views/UserLogin.vue'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useUser } from '../storage/user'

const guard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useUser()
  if (store.identityIsValid === true) {
    next({ name: 'selectNearbySite' })
  } else {
    next()
  }
}

export default {
  beforeEnter: guard,
  component: UserLogin,
  meta: {
    showMenu: false,
  },
  name: 'login',
  path: '/login',
}
