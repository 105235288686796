export default `
query SITE_QUERY($id: String!) {
  site(id: $id) {
    id
    name
    longitude
    latitude
    address
    customer{
      id
      name
      street
      town
      postcode
      state
      phone
      email
      hasInduction
      hasWorkOrder
      hasUnregisteredContractor
      hasCheckList
      hasNotice
      hasGPS
      hasAccreditation
    }
  }
}
`
