import { ContractorCompany, Site } from '../../services/api/site'
import { computed, ComputedRef } from 'vue'
import { useSite } from '../../storage/useSite'

export default function site(): {
  setSiteExist: (existence: boolean) => void
  setSite: (site: Site) => void
  getSiteAddress: () => ComputedRef<string>
  getSiteName: () => ComputedRef<string>
  getSiteId: () => ComputedRef<number>
  getSiteExist: () => ComputedRef<boolean>
  getSiteLatitude: () => ComputedRef<number>
  getSiteLongitude: () => ComputedRef<number>
  resetSite: () => void
  getSiteAuthorisedContractorCompanies: () => ComputedRef<ContractorCompany[]>
  fetchSiteAndCustomer: (siteIdentity: string) => void
  setSiteAuthorisedContractorCompanies: (
    contractorCompanies: ContractorCompany[]
  ) => void
  getSiteSelectionConfirmed: () => ComputedRef<boolean>
  setSiteSelectionConfirmed: (confirmed: boolean) => void
} {
  const store = useSite()

  function getSiteId(): ComputedRef<number> {
    return computed(() => store.id)
  }

  function getSiteLatitude(): ComputedRef<number> {
    return computed(() => store.latitude)
  }

  function getSiteLongitude(): ComputedRef<number> {
    return computed(() => store.longitude)
  }

  function getSiteName(): ComputedRef<string> {
    return computed(() => store.name)
  }

  function getSiteAddress(): ComputedRef<string> {
    return computed(() => store.address)
  }

  function getSiteExist(): ComputedRef<boolean> {
    return computed(() => store.exists)
  }

  function getSiteAuthorisedContractorCompanies(): ComputedRef<
    ContractorCompany[]
  > {
    return computed(() => store.authorisedContractorCompanies)
  }

  function getSiteSelectionConfirmed(): ComputedRef<boolean> {
    return computed(() => store.selectionConfirmed)
  }

  function setSiteAuthorisedContractorCompanies(
    contractorCompanies: ContractorCompany[]
  ) {
    store.authorisedContractorCompanies = contractorCompanies
  }

  function setSiteExist(existence: boolean): void {
    store.exists = existence
  }

  function setSite(site: Site): void {
    setSiteExist(!!site)
    store.name = site.name
    store.address = site.address
    store.id = parseInt(String(site.id))
    store.longitude = site.longitude
    store.latitude = site.latitude
  }

  function resetSite(): void {
    store.reset()
  }

  function fetchSiteAndCustomer(siteIdentity: string): void {
    store.loadSite(siteIdentity)
  }

  function setSiteSelectionConfirmed(confirmed: boolean): void {
    store.selectionConfirmed = confirmed
  }

  return {
    fetchSiteAndCustomer,
    getSiteAddress,
    getSiteAuthorisedContractorCompanies,
    getSiteExist,
    getSiteId,
    getSiteLatitude,
    getSiteLongitude,
    getSiteName,
    getSiteSelectionConfirmed,
    resetSite,
    setSite,
    setSiteAuthorisedContractorCompanies,
    setSiteExist,
    setSiteSelectionConfirmed,
  }
}
