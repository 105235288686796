import axios from 'axios'
import { useAppStore } from '../../../storage/app'

const tokenRequestInterceptor = (): void => {
  axios.interceptors.request.use(
    function (config) {
      const appStore = useAppStore()
      if (config.headers === undefined) {
        config.headers = {
          'x-verified-token': `Bearer ${appStore.token}`,
        }
      } else {
        config.headers['x-verified-token'] = `Bearer ${appStore.token}`
      }

      return Promise.resolve(config)
    },
    function (error) {
      return Promise.reject(error)
    }
  )
}

export default tokenRequestInterceptor
