import { computed, ComputedRef } from 'vue'
import { useUserLogin } from '../../storage/userLogin'

export default function userLogin(): {
  getUserLoginFirstName: () => ComputedRef<string>
  getUserLoginExists: () => ComputedRef<boolean>
  getUserLoginIsVisitor: () => ComputedRef<boolean>
  setUserLoginExists: (exists: boolean) => void
  setUserLoginFirstName: (firstName: string) => void
  setUserLoginIsVisitor: (isVisitor: boolean) => void
} {
  const store = useUserLogin()

  function getUserLoginExists(): ComputedRef<boolean> {
    return computed(() => store.exists)
  }

  function getUserLoginFirstName(): ComputedRef<string> {
    return computed(() => store.firstName)
  }

  function getUserLoginIsVisitor(): ComputedRef<boolean> {
    return computed(() => store.isVisitor)
  }

  function setUserLoginExists(exists: boolean): void {
    store.exists = exists
  }

  function setUserLoginFirstName(firstname: string): void {
    store.firstName = firstname
  }

  function setUserLoginIsVisitor(isVisitor: boolean): void {
    store.isVisitor = isVisitor
  }

  return {
    getUserLoginExists,
    getUserLoginFirstName,
    getUserLoginIsVisitor,
    setUserLoginExists,
    setUserLoginFirstName,
    setUserLoginIsVisitor,
  }
}
