<template>
  <img
    :src="verifiedLogo"
    :alt="alternateText"
    :width="width"
    :height="height"
  />
</template>

<script lang="ts">
  import Logo from './../assets/verified_logo_white.png'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'VerifiedLogo',
    props: {
      alternateText: {
        type: String,
        default: 'Verified',
      },

      height: {
        type: Number,
        default: 100,
      },

      width: {
        type: Number,
        default: 770,
      },
    },

    setup() {
      return {
        verifiedLogo: Logo,
      }
    },
  })
</script>

<style scoped>
  img {
    @apply mx-auto;
  }
</style>
