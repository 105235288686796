<template>
  <textarea
    v-model="value"
    :class="[textareaClasses]"
    class="border border-gray-300 disabled:opacity-50 duration-300 focus:ring-2 outline-none px-2 py-1 rounded-md text-gray-800 w-full"
    :disabled="disabled"
    :maxlength="maxlength"
    :placeholder="placeholder"
    :required="required"
  ></textarea>
</template>

<script lang="ts">
  import { defineComponent, ref, watch } from 'vue'

  export default defineComponent({
    name: 'BaseTextarea',

    props: {
      disabled: {
        type: Boolean,
        default: false,
      },

      maxlength: {
        type: Number,
        default: Infinity,
      },

      placeholder: {
        type: String,
        default: '',
      },

      required: {
        type: Boolean,
        default: false,
      },

      textareaClasses: {
        type: Array,
        default: () => [],
      },
    },

    emits: ['valueChanged'],

    setup(prop, { emit }) {
      const value = ref('')

      watch(value, () => {
        emit('valueChanged', value)
      })

      return {
        value,
      }
    },
  })
</script>
