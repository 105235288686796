<template>
  <div v-show="shouldShow" :class="name" class="my-4">
    <span class="block mb-2" :class="{ 'text-red-500': !!errorMessage }">
      {{ content }} <br />
      {{ `(max ${max} photos)` }}
    </span>
    <div class="w-14 bg-blue-500 rounded border-2 border-blue-500">
      <label :for="name">
        <CameraIcon class="h-8 text-white w-7 m-auto" />
        <input
          :id="name"
          type="file"
          accept="image/*"
          class="hidden block"
          :disabled="
            Object.values(uploadedPhotos).filter(
              (photo) => photo.value !== null
            ).length === max
          "
          @change="receiveAndSavePhoto"
        />
      </label>
    </div>

    <div :id="name + '-canvas'">
      <canvas
        v-for="index in Object.values(uploadedPhotos).filter(
          (photo) => photo.value !== null
        ).length"
        :id="name + '-canvas-' + index"
        :key="index"
        class="border-2 border-blue-500 border-dashed h-32 inline-block mr-3 mt-5 w-24"
        @touchstart="inspectPhoto"
      />
    </div>

    <div
      v-if="viewPhoto"
      class="fixed bg-black bottom-0 h-screen left-0 right-0 top-0 z-10"
    >
      <div class="grid grid-cols-2 p-4 pb-20">
        <button type="button" @click="closePhotoDialogue">
          <ArrowLeftIcon class="h-6 text-white w-6" />
        </button>
        <button
          type="button"
          class="left-3/4 pl-5 relative"
          @click="removePhoto"
        >
          <TrashIcon class="h-6 text-white w-6" />
        </button>
      </div>
      <div class="flex h-3/5 justify-center w-full">
        <img
          id="photoInspection"
          alt="Sorry, something went wrong"
          class="border-2 border-gray-700 border-solid m-auto object-cover"
          :src="Object.values(photoViewerSource)[0]"
        />
      </div>
    </div>
    <LoadingView :is-loading="isLoading" :message="loadingMessage" />
    <span class="mt-1 mb-1 text-red-500">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts">
  import { defineComponent, nextTick, ref, watch } from 'vue'
  import { ArrowLeftIcon, CameraIcon, TrashIcon } from '@heroicons/vue/outline'
  import useSite from '../use/site'
  import useServices from '../use/services'
  import useCustomer from '../use/customer'
  import useUser from '../use/user'
  import {
    removeFormPhoto,
    storeFormPhoto,
  } from '../../services/api/serviceForm'
  import loadImage from 'blueimp-load-image'
  import LoadingView from '../../views/LoadingView.vue'

  export default defineComponent({
    name: 'PhotoInput',

    components: { ArrowLeftIcon, CameraIcon, LoadingView, TrashIcon },
    props: {
      // eslint-disable-next-line vue/no-unused-properties
      conditions: {
        type: Array,
        default: () => undefined,
      },

      content: {
        type: String,
        default: '',
      },

      errorMessage: {
        type: String,
        default: '',
      },

      max: {
        type: Number,
        default: Infinity,
      },

      name: {
        type: String,
        default: '',
      },

      show: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['formChanged'],

    setup(props, { emit }) {
      const viewPhoto = ref(false)
      const shouldShow = ref(props.show)
      const photoViewerSource = ref<{ [key: string]: string }>({})
      const uploadedPhotos = ref<{
        [key: string]: {
          id: string | null
          value: HTMLImageElement | null
        }
      }>({})
      const identifier = ref<string>(props.name)
      const { getSiteId } = useSite()
      const { getSelectedService } = useServices()
      const { getCustomerId } = useCustomer()
      const { getUserId } = useUser()
      const siteId = getSiteId()
      const selectedService = getSelectedService()
      const customerId = getCustomerId()
      const userId = getUserId()
      const isLoading = ref(false)
      const loadingMessage = ref('')
      let sequenceOfPhoto = 1

      watch(shouldShow, () => {
        if (!shouldShow.value) {
          initialiseUploadedPhotosObject()
        }
      })

      watch(
        () => uploadedPhotos.value,
        () => {
          eraseImagesOnCanvas()
          nextTick(() => {
            const imageDataUrls = Object.values(uploadedPhotos.value)
              .filter((photo) => photo.value !== null)
              .map((photo) => {
                drawImageOnCanvas(photo.value as HTMLImageElement)
                return photo.id
              })
            emit('formChanged', imageDataUrls)
          })
        },
        { deep: true }
      )

      function initialiseUploadedPhotosObject() {
        for (let i = 1; i <= props.max; i++) {
          uploadedPhotos.value[`${props.name}-canvas-${i}`] = {
            id: null,
            value: null,
          }
        }
      }

      function findEmptyCanvas() {
        let emptyCanvas = null
        const canvasNodeList = document.querySelectorAll(
          `#${props.name}-canvas canvas`
        )
        const allCanvasElements = Array.from(canvasNodeList)

        for (let element of allCanvasElements) {
          const canvasElement = element as HTMLCanvasElement
          const context = canvasElement.getContext('2d')
          let isEmpty = false
          if (context) {
            const pixelBuffer = new Uint32Array(
              context.getImageData(
                0,
                0,
                canvasElement.width,
                canvasElement.height
              ).data.buffer
            )
            isEmpty = !pixelBuffer.some((color) => color !== 0)
          }
          if (isEmpty) {
            emptyCanvas = canvasElement
            break
          }
        }
        return emptyCanvas
      }

      function drawImageOnCanvas(image: HTMLImageElement) {
        const canvas = findEmptyCanvas()

        if (canvas !== null) {
          const canvasContext = canvas.getContext('2d')
          canvasContext?.drawImage(image, 0, 0, canvas.width, canvas.height)
        }
      }

      function eraseImagesOnCanvas() {
        const canvasNodeList = document.querySelectorAll(
          `#${props.name}-canvas canvas`
        )
        const allCanvasElements = Array.from(canvasNodeList)
        for (let canvas of allCanvasElements as Array<HTMLCanvasElement>) {
          const canvasContext = canvas.getContext('2d')
          canvasContext?.clearRect(0, 0, canvas.width, canvas.height)
        }
      }

      async function persistServiceRecordImage(imageString: string) {
        const inspectionPhoto =
          props.name === 'inspectionPhoto' ? imageString : null
        const defectPhoto = props.name === 'defectPhoto' ? imageString : null

        return await storeFormPhoto(
          selectedService.value.id,
          customerId.value,
          siteId.value.toString(),
          selectedService.value.subServiceId,
          userId.value.toString(),
          inspectionPhoto,
          defectPhoto,
          sequenceOfPhoto.toString()
        )
      }

      function storePhoto(rawImage: HTMLCanvasElement) {
        Object.keys(uploadedPhotos.value).length === 0 &&
          initialiseUploadedPhotosObject()
        for (const [key, photo] of Object.entries(uploadedPhotos.value)) {
          if (photo.id === null && photo.value === null) {
            const resizedImage = new Image()
            resizedImage.src = rawImage.toDataURL('image/jpeg', 0.7)
            resizedImage.onload = function () {
              isLoading.value = true
              loadingMessage.value = 'Saving photo'
              const targetPhoto = uploadedPhotos.value[key]
              targetPhoto.value = resizedImage
              persistServiceRecordImage(resizedImage.src).then(
                (photoId: string) => {
                  targetPhoto.id = photoId
                  isLoading.value = false
                  loadingMessage.value = ''
                  sequenceOfPhoto++
                }
              )
            }
            break
          }
        }
      }

      function receiveAndSavePhoto(evt: Event | unknown) {
        const target = (evt as Event).target as HTMLInputElement & EventTarget
        const imageFile = (target.files as FileList)[0]
        const options = {
          canvas: true,
          downsamplingRatio: 1,
          maxWidth: 1024,
          minWidth: 1024,
        }

        if (imageFile) {
          loadImage(imageFile, storePhoto, options)
        }
      }

      function inspectPhoto(evt: TouchEvent) {
        const target = evt.target as HTMLCanvasElement
        const key = target.id
        const photoExists =
          key in uploadedPhotos.value &&
          uploadedPhotos.value[key].value !== null
        if (photoExists) {
          photoViewerSource.value[key] = (
            uploadedPhotos.value[key].value as HTMLImageElement
          ).src
          viewPhoto.value = true
        }
      }

      function closePhotoDialogue() {
        viewPhoto.value = false
        photoViewerSource.value = {}
      }

      function removePhoto() {
        const objectNotEmpty = Object.keys(photoViewerSource.value).length !== 0
        const hasPhotoId =
          !!uploadedPhotos.value[Object.keys(photoViewerSource.value)[0]].id

        if (objectNotEmpty && hasPhotoId) {
          const key = Object.keys(photoViewerSource.value)[0]
          const targetPhoto = uploadedPhotos.value[key]
          isLoading.value = true
          loadingMessage.value = 'Removing photo'
          removeFormPhoto(targetPhoto.id as string).then((responseCode) => {
            if (responseCode === 200) {
              targetPhoto.id = null
              targetPhoto.value = null
              sortUploadedPhotosProperty()
              isLoading.value = false
              loadingMessage.value = ''
              closePhotoDialogue()
            }
          })
        }
      }

      function sortUploadedPhotosProperty() {
        const notNullPhotoObjects = Object.values(uploadedPhotos.value).filter(
          (photo) => photo.id !== null && photo.value !== null
        )
        initialiseUploadedPhotosObject()

        notNullPhotoObjects.forEach((photo) => {
          for (const [key, value] of Object.entries(uploadedPhotos.value)) {
            if (value.id === null && value.value === null) {
              uploadedPhotos.value[key] = photo
              break
            }
          }
        })
      }

      return {
        closePhotoDialogue,
        identifier,
        inspectPhoto,
        isLoading,
        loadingMessage,
        photoViewerSource,
        receiveAndSavePhoto,
        removePhoto,
        shouldShow,
        uploadedPhotos,
        viewPhoto,
      }
    },
  })
</script>
