<template>
  <div class="p-4">
    <TitleBanner title="Are you sure you want to checkout?" />
    <div class="mt-3">
      <BaseButton
        action="YES"
        identifier="bypass-checkout-yes"
        @click="confirmCheckout"
      />
    </div>
    <div class="mt-3">
      <BaseButton
        action="NO"
        identifier="bypass-checkout-no"
        @click="cancelCheckout"
      />
    </div>
    <LoadingView :is-loading="checkoutConfirmed" />
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import TitleBanner from '../components/TitleBanner.vue'
  import BaseButton from '../components/BaseButton.vue'
  import {
    onBeforeRouteLeave,
    RouteLocationNormalized,
    useRouter,
  } from 'vue-router'
  import checkout from '../components/use/checkout'
  import useSite from '../components/use/site'
  import useUser from '../components/use/user'
  import { postCheckoutData } from '../services/api/checkout'
  import usePosition from '../components/use/position'
  import LoadingView from './LoadingView.vue'

  export default defineComponent({
    name: 'CheckoutView',
    components: { BaseButton, LoadingView, TitleBanner },

    setup() {
      const router = useRouter()
      const { getUserId } = useUser()
      const { getSiteId } = useSite()
      const { getUserPosition } = usePosition()
      const checkoutConfirmed = ref(false)

      onBeforeRouteLeave((to: RouteLocationNormalized) => {
        if (to.name === 'serviceSelection' && checkoutConfirmed.value) {
          return false
        }
      })

      async function acquireRequiredData() {
        const location = getUserPosition()
        const userId = getUserId()
        const siteId = getSiteId()

        return {
          location: {
            accuracy: location.value.accuracy,
            altitude: location.value.altitude,
            latitude: location.value.latitude,
            longitude: location.value.longitude,
          },

          siteId: siteId.value.toString(),
          userId: userId.value.toString(),
        }
      }

      async function confirmCheckout() {
        checkoutConfirmed.value = true
        const response = await checkout(acquireRequiredData, postCheckoutData)
        response.code === 200 && (await router.push({ name: 'thankYouPage' }))
      }

      function cancelCheckout() {
        router.back()
      }

      return {
        cancelCheckout,
        checkoutConfirmed,
        confirmCheckout,
      }
    },
  })
</script>
