<template>
  <div class="shadow-md py-3">
    <h1 class="font-medium pt-1 text-center text-xl">
      {{ siteName }}
    </h1>
    <p class="py-1 text-center text-xs">{{ siteAddress }}</p>
  </div>

  <div class="px-6 text-center my-5 text-lg">
    <span class="block w-12 h-12 mx-auto text-blue-500">
      <CheckCircleIcon />
    </span>
    <span class="block my-1">Success</span>
    <span class="block">Conf No. {{ confirmationCode }}</span>
  </div>

  <hr class="text-gray-300 border-1" />

  <div class="my-2 px-6 text-center">
    <span class="block text-lg my-4"
      >Do you have more services to complete?</span
    >
    <div class="grid grid-cols-2 gap-2">
      <BaseButton
        identifier="more-services-button"
        action="MORE SERVICES"
        @click="router.push({ name: 'serviceSelection' })"
      />
      <BaseButton
        identifier="check-out-button"
        action="CHECKOUT"
        @click="checkout"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue'
  import useSite from '../components/use/site'
  import useServices from '../components/use/services'
  import useForm from '../components/use/form'
  import { useRoute, useRouter } from 'vue-router'
  import BaseButton from '../components/BaseButton.vue'
  import { CheckCircleIcon } from '@heroicons/vue/solid'

  export default defineComponent({
    name: 'ServiceCheckout',
    components: { BaseButton, CheckCircleIcon },

    setup() {
      const { getSiteAddress, getSiteName } = useSite()
      const { getSelectedService } = useServices()
      const { setServiceRecordId } = useForm()
      const siteName = getSiteName()
      const siteAddress = getSiteAddress()
      const selectedService = getSelectedService()
      const router = useRouter()
      const route = useRoute()
      const confirmationCode = route?.params?.confirmationCode
      const showBanner = ref(true)

      onMounted(() => {
        setTimeout(() => (showBanner.value = false), 2500)
        setServiceRecordId('')
      })

      function checkout() {
        router.push({ name: 'checkout' })
      }

      return {
        checkout,
        confirmationCode,
        router,
        selectedService,
        showBanner,
        siteAddress,
        siteName,
      }
    },
  })
</script>
