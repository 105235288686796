<template>
  <div class="mx-4">
    <div class="my-2">
      <TitleBanner title="Site Selected" />
    </div>
    <p class="font-medium pt-1 text-2xl text-center">
      {{ siteName }}
    </p>
    <p class="py-1 text-center text-lg">{{ siteAddress }}</p>
    <div class="w-full">
      <GoogleMap
        :api-key="apiKey"
        :center="center"
        :zoom="15"
        class="h-80 w-full"
        :fullscreen-control="fullScreen"
      >
        <Pin :options="{ position: center }" class="caret-green-600" />
      </GoogleMap>
    </div>
    <p class="my-2 pt-1 text-center text-xl">Is this the correct site?</p>
    <div class="flex pt-3 space-x-4">
      <BaseButton
        type="button"
        action="NO"
        identifier="NoBtn"
        @click="backToSiteList"
      />
      <BaseButton
        type="button"
        action="YES"
        identifier="YesBtn"
        @click="confirmSite"
      />
    </div>
    <LoadingView :is-loading="isLoading" />
  </div>
</template>

<script lang="ts">
  import { defineComponent, inject, ref } from 'vue'
  import TitleBanner from '../components/TitleBanner.vue'
  import BaseButton from '../components/BaseButton.vue'
  import { GoogleMap, Marker as Pin } from 'vue3-google-map'
  import { useRouter } from 'vue-router'
  import useSite from '../components/use/site'
  import {
    ContractorCompany,
    getAuthorisedContractorCompanies,
  } from '../services/api/site'
  import useUser from '../components/use/user'
  import LoadingView from './LoadingView.vue'
  import { useNativeScan } from '../storage/useNativeScan'
  import useBypass from '../components/use/bypass'

  export default defineComponent({
    name: 'ConfirmNearbySite',
    components: {
      BaseButton,
      GoogleMap,
      LoadingView,
      Pin,
      TitleBanner,
    },

    async setup() {
      const {
        getSiteId,
        getSiteAddress,
        getSiteName,
        getSiteLongitude,
        getSiteLatitude,
        setSiteAuthorisedContractorCompanies,
        setSiteSelectionConfirmed,
      } = useSite()
      const router = useRouter()
      const { getUserId } = useUser()
      const { setScanBypassed } = useBypass()

      const center = {
        lat: getSiteLatitude().value,
        lng: getSiteLongitude().value,
      }
      const apiKey = inject('config.google.apiKey')
      const fullScreen = ref(false)
      const siteName = getSiteName()
      const siteAddress = getSiteAddress()
      const siteId = getSiteId()
      const userId = getUserId()
      const authorisedContractorCompanies = ref<ContractorCompany[]>([])
      const isLoading = ref(false)

      function backToSiteList(): void {
        setSiteSelectionConfirmed(false)
        router.push({ name: 'selectNearbySite' })
      }

      async function confirmSite(): Promise<void> {
        isLoading.value = true
        authorisedContractorCompanies.value =
          await getAuthorisedContractorCompanies(siteId.value, userId.value)
        isLoading.value = false
        if (authorisedContractorCompanies.value.length !== 0) {
          setScanBypassed(false)
          setSiteSelectionConfirmed(true)
          setSiteAuthorisedContractorCompanies(
            authorisedContractorCompanies.value
          )
          await router.push({ name: 'contractorCompanySelection' })
        }
      }

      // allow skipping this confirmation if native scanned QR code
      if (useNativeScan().uuid?.length === 36) {
        await confirmSite()
      }

      return {
        apiKey,
        backToSiteList,
        center,
        confirmSite,
        fullScreen,
        isLoading,
        siteAddress,
        siteName,
      }
    },
  })
</script>
