import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import config from '../../config'
import { isMacOsDesktopDevice } from '../../utilities/device'

const detectDesktopDeviceGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  // Desktop device detected
  if (config.debug === false && isMacOsDesktopDevice()) {
    window.location.href = config.redirects.desktop.toString()
    return
  }

  next()
}

export { detectDesktopDeviceGuard }
