import { RouteLocationNormalized, RouteRecordName } from 'vue-router'
import savePoint from '../../components/use/savePoint'

const bypassRoutes = [
  'bypass',
  'changePin',
  'forgotPin',
  'help',
  'helpLocationPermission',
  'helpNoPrivateBrowsing',
  'history',
]

const savePointFinaliser = (to: RouteLocationNormalized) => {
  if (!bypassRoutes.includes(to.name as string)) {
    savePoint().setSavePoint({
      name: to.name as RouteRecordName,
      params: to.params,
    })
  }
}

export { savePointFinaliser }
