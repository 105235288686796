export default `
  mutation siteSelected(
    $bypass: Bypass
    $contractorCompanyId: String!,
    $location: Location!,
    $siteId: String!,
    $userId: String!,
  ) {
    siteSelected(
      bypass: $bypass,
      contractorCompanyId: $contractorCompanyId,
      location: $location,
      siteId: $siteId,
      userId: $userId,
    ) {
      message
      code
    }
  }
`
