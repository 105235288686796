import { useNativeScan } from './storage/useNativeScan'

const scanUuid = () => {
  const uuid = new URLSearchParams(window.location.search).get('uuid')

  const store = useNativeScan()

  if (uuid) {
    store.uuid = <string>uuid
  } else {
    store.reset()
  }
}

export { scanUuid }
