<template>
  <TitleBanner title="Change PIN" />
  <div class="p-4">
    <div class="p-0 border border-gray-300 rounded-md mb-4">
      <PhoneNumberInput
        :initial-phone-number="phoneNumber"
        :default-country-code="countryCode"
        :disabled="true"
      />
    </div>
    <div class="mb-4">
      <PinInput
        label="Current Pin"
        :pin-input-changed="pins.currentPin.value"
        :pin-validation="currentPinIsValid"
        @value-changed="validateEnteredPin($event, 'currentPin')"
      />
      <div class="input-error mt-1 pl-2 text-red-500">
        {{ pins.currentPin.errorMessage }}
      </div>
    </div>
    <div class="mb-4">
      <PinInput
        autocomplete="new-password"
        label="New Pin"
        :pin-input-changed="pins.newPin.value"
        :pin-validation="newPinIsValid"
        @value-changed="validateEnteredPin($event, 'newPin')"
      />
      <div class="input-error mt-1 pl-2 text-red-500">
        {{ pins.newPin.errorMessage }}
      </div>
    </div>
    <div class="mb-4">
      <PinInput
        autocomplete="new-password"
        label="Re-enter New Pin"
        :pin-input-changed="pins.reEnteredNewPin.value"
        :pin-validation="newPinIsValid"
        @value-changed="validateEnteredPin($event, 'reEnteredNewPin')"
      />
      <div class="input-error mt-1 pl-2 text-red-500">
        {{ pins.reEnteredNewPin.errorMessage }}
      </div>
    </div>
    <div class="grid grid-cols-2 gap-2">
      <BaseButton
        action="SAVE"
        type="submit"
        identifier="save-new-pin"
        @click="confirmChange"
      />
      <BaseButton
        action="CANCEL"
        identifier="cancel-pin-reset"
        @click="cancelChange"
      />
    </div>
    <div
      v-if="showSuccessMessage"
      class="fixed h-screen w-full bottom-0 top-0 left-0 right-0 bg-gray-400 bg-opacity-60 flex flex-wrap content-center justify-center"
    >
      <div
        class="bg-white p-4 rounded-sm transition duration-300 grid grid-cols-2 gap-2 text-center w-9/12"
      >
        <span class="block w-full p-0 m-0 mb-4 col-span-2">
          You have successfully changed your pin.
        </span>
        <button
          type="button"
          class="py-1 bg-blue-500 rounded-sm col-start-2"
          @click="router.go(-1)"
        >
          OK
        </button>
      </div>
    </div>
    <LoadingView :is-loading="isLoading" />
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, nextTick, onMounted, ref } from 'vue'
  import TitleBanner from '../components/TitleBanner.vue'
  import PhoneNumberInput from '../components/PhoneNumberInput.vue'
  import PinInput from '../components/PinInput.vue'
  import usePhoneNumber from '../components/use/phoneNumber'
  import useCountry from '../components/use/countries'
  import useUser from '../components/use/user'
  import BaseButton from '../components/BaseButton.vue'
  import { useRouter } from 'vue-router'
  import { changePin, authenticateUser } from '../services/api/user'
  import LoadingView from './LoadingView.vue'
  import setFocus from '../components/helpers/setFocus'

  export default defineComponent({
    name: 'ChangePin',
    components: {
      BaseButton,
      LoadingView,
      PhoneNumberInput,
      PinInput,
      TitleBanner,
    },

    setup() {
      const { getCountryByInternationalPhoneNumber } = useCountry()
      const { getPhoneNumber } = usePhoneNumber()
      const { getUserId } = useUser()
      const phoneNumber = getPhoneNumber()
      const countryCode = getCountryByInternationalPhoneNumber(
        phoneNumber.value
      )?.value.countryCode

      const pins = ref<{ [key: string]: { [key: string]: string } }>({
        currentPin: {
          errorMessage: '',
          value: '',
        },
        newPin: {
          errorMessage: '',
          value: '',
        },
        reEnteredNewPin: {
          errorMessage: '',
          value: '',
        },
      })

      const currentPinIsValid = computed(
        () => pins.value.currentPin.errorMessage === ''
      )
      const newPinIsValid = computed(
        () => pins.value.newPin.errorMessage === ''
      )
      const router = useRouter()
      const showSuccessMessage = ref(false)
      const isLoading = ref(false)

      onMounted(() => {
        nextTick(function () {
          setFocus()
        })
      })

      function validateEnteredPin(pinEntered: string, fieldName: string): void {
        const lengthOfPin = pinEntered.length
        const areNumbers = /^[0-9]+$/
        if (lengthOfPin !== 4) {
          pins.value[fieldName].errorMessage = '4 digits are required'
        } else if (lengthOfPin === 4 && !areNumbers.test(pinEntered)) {
          pins.value[fieldName].errorMessage = 'Please enter numbers only'
        } else if (lengthOfPin === 4 && areNumbers.test(pinEntered)) {
          pins.value[fieldName].errorMessage = ''
          pins.value[fieldName].value = pinEntered
        }
      }

      function confirmNewPin() {
        if (pins.value.newPin.value !== pins.value.reEnteredNewPin.value) {
          pins.value.newPin.errorMessage = "PIN doesn't match"
          pins.value.reEnteredNewPin.errorMessage = "PIN doesn't match"
          return false
        }
        return true
      }

      async function checkUserIdentity(phoneNumber: string, pin: string) {
        try {
          await authenticateUser(phoneNumber, pin)
        } catch {
          pins.value.currentPin.errorMessage = 'Current pin is invalid'
          return false
        }
        return true
      }

      async function sendChangePinRequest(
        identityIsValid: boolean,
        newPinConfirmed: boolean
      ) {
        if (!identityIsValid || !newPinConfirmed) {
          return
        }
        const userId = getUserId()
        const response = await changePin(
          userId.value.toString(),
          pins.value.newPin.value
        )
        if (response.code === 200) {
          showSuccessMessage.value = true
        }
      }

      async function confirmChange() {
        isLoading.value = true
        for (const pin of Object.values(pins.value)) {
          if (pin.errorMessage !== '') {
            isLoading.value = false
            return
          }
        }
        const newPinConfirmed = confirmNewPin()
        const identityIsValid = await checkUserIdentity(
          phoneNumber.value,
          pins.value.currentPin.value
        )
        await sendChangePinRequest(identityIsValid, newPinConfirmed)
        isLoading.value = false
      }

      function cancelChange() {
        router.go(-1)
      }

      return {
        cancelChange,
        confirmChange,
        countryCode,
        currentPinIsValid,
        isLoading,
        newPinIsValid,
        phoneNumber,
        pins,
        router,
        showSuccessMessage,
        validateEnteredPin,
      }
    },
  })
</script>
