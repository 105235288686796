export default `
query BYPASS_REASON_QUERY {
   bypassReasons {
    id
    name
    hasDate
    hasDescription
  }
}
`
