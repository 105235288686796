import querySiteServiceFrequencies from '../../graphql/queries/querySiteServiceFrequencies'
import queryServiceFrequencies from '../../graphql/queries/queryServiceFrequencies'
import * as io from 'io-ts'
import decodeToPromise from './typeChecker'
import axios from 'axios'

export type Frequency = io.TypeOf<typeof frequency>

const frequency = io.type({
  description: io.string,
  id: io.string,
})

const frequencyTypeChecker = io.array(frequency)

export const getSiteServiceFrequencies = (
  siteId: number,
  serviceId: string,
  subServiceId: string
): Promise<Frequency[]> => {
  return axios
    .get('/graphql', {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        query: querySiteServiceFrequencies,
        variables: {
          serviceId: serviceId,
          siteId: siteId,
          subServiceId: subServiceId,
        },
      },
    })
    .then((response) => response?.data?.data?.siteServiceFrequencies)
    .then((data) => decodeToPromise(frequencyTypeChecker, data))
}

export const getServiceFrequencies = (
  serviceId: string
): Promise<Frequency[]> => {
  return axios
    .get('/graphql', {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        query: queryServiceFrequencies,
        variables: {
          serviceId: serviceId,
        },
      },
    })
    .then((response) => response?.data?.data?.serviceFrequencies)
    .then((data) => decodeToPromise(frequencyTypeChecker, data))
}
