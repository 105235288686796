import BypassSiteRoute from './bypassSite'
import ChangePinRoute from './changePin'
import ServiceCheckoutRoute from './serviceCheckout'
import CheckoutRoute from './checkout'
import CheckoutThankYouRoute from './checkoutThankYou'
import ConfirmBypassSiteRoute from './confirmBypassSite'
import ConfirmNearbySiteRoute from './confirmNearbySite'
import ContractorCompanySelectionRoute from './contractorCompanySelection'
import ForgotPinRoute from './forgotPin'
import UserHelpRoute from './userHelp'
import HelpLocationRoute from './helpLocation'
import HelpNoPrivateBrowsing from './helpNoPrivateBrowsing'
import UserHistoryRoute from './userHistory'
import HomeRoute from './home'
import UserLoginRoute from './userLogin'
import SelectBypassSitesRoute from './selectBypassSites'
import SelectNearbySiteRoute from './selectNearbySite'
import ServiceFormRoute from './serviceForm'
import ServiceFrequencyRoute from './serviceFrequency'
import ServiceSelectionRoute from './serviceSelection'

export default [
  BypassSiteRoute,
  ChangePinRoute,
  CheckoutRoute,
  CheckoutThankYouRoute,
  ConfirmBypassSiteRoute,
  ConfirmNearbySiteRoute,
  ContractorCompanySelectionRoute,
  ForgotPinRoute,
  HelpLocationRoute,
  HelpNoPrivateBrowsing,
  HomeRoute,
  UserLoginRoute,
  SelectBypassSitesRoute,
  SelectNearbySiteRoute,
  ServiceCheckoutRoute,
  ServiceFormRoute,
  ServiceFrequencyRoute,
  ServiceSelectionRoute,
  UserHelpRoute,
  UserHistoryRoute,
]
