<template>
  <div class="relative">
    <label
      v-if="inputValue"
      :class="{ 'text-blue-400': isValid, 'text-red-500': !isValid }"
      class="absolute left-2 text-xs top-1"
      >{{ label }}
    </label>
    <input
      :value="inputValue"
      :class="[
        identifier,
        inputClasses,
        {
          'text-red-500': !isValid,
          'pt-4': inputValue,
          'focus:ring-red-500': !isValid,
          'focus:ring-blue-400': isValid,
        },
      ]"
      :placeholder="label"
      :required="required"
      :type="type"
      :name="identifier"
      :inputmode="inputMode"
      :pattern="pattern"
      :maxlength="maxlength"
      :disabled="disabled"
      class="border border-gray-300 disabled:opacity-50 duration-300 focus:ring-2 h-12 outline-none px-2 py-1 rounded-md text-gray-800 w-full"
      autocomplete="off"
      @keypress="$emit('keyPressed', $event)"
      @input="changeValue($event.target)"
    />
    <slot></slot>
  </div>
</template>

<script lang="ts">
  import { defineComponent, HTMLAttributes, PropType } from 'vue'

  export default defineComponent({
    name: 'BaseInput',

    props: {
      disabled: {
        type: Boolean,
        default: false,
      },

      identifier: {
        type: String,
        default: '',
      },

      inputClasses: {
        type: Array,
        default: () => [],
      },

      inputMode: {
        type: String as PropType<HTMLAttributes['inputmode']>,
        default: 'text',
      },

      inputValue: {
        type: String,
        default: '',
      },

      isValid: {
        type: Boolean,
        default: false,
      },

      label: {
        type: String,
        default: '',
      },

      maxlength: {
        type: Number,
        default: Number.POSITIVE_INFINITY,
      },

      pattern: {
        type: String,
        default: '',
      },

      required: {
        type: Boolean,
        default: false,
      },

      type: {
        type: String,
        default: 'text',
      },
    },

    emits: ['keyPressed', 'update:inputValue'],

    setup(props, { emit }) {
      function changeValue(event: EventTarget | null): void {
        emit('update:inputValue', (event as HTMLInputElement)?.value)
      }

      return {
        changeValue,
      }
    },
  })
</script>
