<template>
  <div
    v-if="message"
    class="error-view fixed h-screen w-screen flex flex-wrap content-center justify-center"
  >
    <div class="w-11/12">
      <div class="bg-red-100 rounded-md flex flex-wrap grow-0 mb-3">
        <p class="p-6">{{ message }}</p>
      </div>
      <div class="w-full">
        <BaseButton
          button-classes="border-2 border-zinc-500 text-zinc-500 rounded-md"
          action="OK"
          @click="closeErrorView"
        ></BaseButton>
      </div>
    </div>
  </div>
  <slot v-else></slot>
</template>

<script lang="ts">
  import { defineComponent, onErrorCaptured, ref } from 'vue'
  import BaseButton from '../components/BaseButton.vue'

  export default defineComponent({
    name: 'ErrorView',
    components: { BaseButton },
    setup() {
      const message = ref('')

      onErrorCaptured((err) => {
        message.value = err.message
        return false
      })

      function closeErrorView() {
        message.value = ''
      }

      return {
        closeErrorView,
        message,
      }
    },
  })
</script>
