import ThankYouPage from '../views/ThankYouPage.vue'

export default {
  component: ThankYouPage,
  meta: {
    showMenu: false,
  },
  name: 'thankYouPage',
  path: '/checkout/thank-you',
}
