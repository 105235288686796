import ServiceCheckout from '../views/ServiceCheckout.vue'

export default {
  component: ServiceCheckout,
  meta: {
    back: 'serviceSelection',
    showMenu: true,
  },
  name: 'serviceCheckout',
  path: '/checkout/:confirmationCode',
}
