import useSite from './site'
import useNativeScan from './nativeScan'
import useServices from './services'
import useCustomer from './customer'
import useFrequency from './frequency'
import usePosition from './position'
import useForm from './form'
import useUser from './user'
import useBypass from './bypass'
import savePoint from './savePoint'

export default function resetStore(): void {
  const { resetSite } = useSite()
  const { resetServiceData } = useServices()
  const { resetCustomerData } = useCustomer()
  const { resetFrequency } = useFrequency()
  const { resetPosition } = usePosition()
  const { resetFormStore } = useForm()
  const { resetUserStore } = useUser()
  const { resetNativeScan } = useNativeScan()
  const { resetBypass } = useBypass()
  const { resetSavePoint } = savePoint()

  resetSite()
  resetServiceData()
  resetCustomerData()
  resetFrequency()
  resetPosition()
  resetFormStore()
  resetUserStore()
  resetNativeScan()
  resetBypass()
  resetSavePoint()
}
