import axios from 'axios'
import query from '../../graphql/queries/querySite'
import querySites from '../../graphql/queries/querySites'
import queryContractorCompanies from '../../graphql/queries/queryContractorCompanies'
import * as io from 'io-ts'
import decodeToPromise from './typeChecker'
import config from '../../config'

export type Site = io.TypeOf<typeof siteTypeChecker>
export type Customer = io.TypeOf<typeof customerTypeChecker>
export type ContractorCompany = io.TypeOf<typeof contractorCompanyTypeChecker>
export let controller: AbortController = new AbortController()

const customerTypeChecker = io.type({
  email: io.string,
  hasAccreditation: io.boolean,
  hasCheckList: io.boolean,
  hasGPS: io.boolean,
  hasInduction: io.boolean,
  hasNotice: io.boolean,
  hasUnregisteredContractor: io.boolean,
  hasWorkOrder: io.boolean,
  id: io.string,
  name: io.string,
  phone: io.string,
  postcode: io.string,
  state: io.string,
  street: io.string,
  town: io.string,
})

const siteTypeChecker = io.type({
  address: io.string,
  customer: customerTypeChecker,
  id: io.number,
  latitude: io.number,
  longitude: io.number,
  name: io.string,
})

const sitesTypeChecker = io.array(siteTypeChecker)

const contractorCompanyTypeChecker = io.type({
  id: io.number,
  name: io.string,
})

const contractorCompaniesTypeChecker = io.array(contractorCompanyTypeChecker)

export const getSite = async (code: string): Promise<Site> => {
  return await axios
    .get('/graphql', {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        query,
        variables: {
          id: code,
        },
      },
    })
    .then((response) => response?.data?.data?.site)
    .then((data) => {
      data.id = parseInt(data.id)
      return decodeToPromise(siteTypeChecker, data)
    })
    .catch((error) => {
      throw error.message
    })
}

export const getSites = async (
  userId: string,
  location: Position,
  pageNumber: string,
  searchQuery: string | null = null,
  withinRadius: number | null = null
): Promise<Site[] | undefined> => {
  controller = new AbortController()
  try {
    const response = await axios.get('/graphql', {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        query: querySites,
        variables: {
          location: location,
          pageNumber: pageNumber,
          perPage: config.pagination.sites.perPage,
          searchQuery: searchQuery,
          userId: userId,
          withinRadius: withinRadius,
        },
      },
      signal: controller.signal,
    })
    const data = response?.data?.data?.sites?.data

    if (data) {
      const dataWithIntId = data.map(
        (site: { id: string | number } & Omit<Site, 'id'>) => {
          site.id = parseInt(site.id as unknown as string)
          return site
        }
      )
      return await decodeToPromise(sitesTypeChecker, dataWithIntId)
    }
    return []
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.message !== 'canceled') {
      return []
    }
  }
}

export const getAuthorisedContractorCompanies = async (
  siteId: number,
  userId: number
): Promise<ContractorCompany[]> => {
  const response = await axios.get('/graphql', {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      query: queryContractorCompanies,
      variables: {
        siteId: siteId,
        userId: userId,
      },
    },
  })
  const data = response?.data?.data?.contractorCompanies
  return await decodeToPromise(contractorCompaniesTypeChecker, data)
}
