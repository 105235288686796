<template>
  <div>
    <Component
      :is="field.component"
      v-for="(field, index) in schema"
      :key="index"
      v-bind="field"
      :ref="
        (el) => {
          children[index] = el
        }
      "
      @form-changed="rerenderForm(field.name, $event)"
    >
    </Component>
  </div>
</template>

<script lang="ts">
  import {
    ComponentPublicInstance,
    defineComponent,
    nextTick,
    onBeforeUpdate,
    onMounted,
    PropType,
    ref,
  } from 'vue'
  import {
    Answers,
    frequency,
    renderConditions,
    SchemaType,
  } from '../../config'
  import MultipleChoiceInput from './MultipleChoiceInput.vue'
  import TextInput from './TextInput.vue'
  import TextareaInput from './TextareaInput.vue'
  import PhotoInput from './PhotoInput.vue'
  import SignatureInput from './SignatureInput.vue'
  import CheckboxInput from './CheckboxInput.vue'

  type ComponentInstanceType =
    | typeof MultipleChoiceInput
    | typeof CheckboxInput
    | typeof PhotoInput
    | typeof SignatureInput
    | typeof TextareaInput
    | typeof TextInput

  export default defineComponent({
    name: 'FormGenerator',

    components: {
      CheckboxInput,
      MultipleChoiceInput,
      PhotoInput,
      SignatureInput,
      TextInput,
      TextareaInput,
    },

    props: {
      schema: {
        type: Array as PropType<SchemaType[]>,
        default: () => [],
      },

      selectedFrequency: {
        type: Array as PropType<Array<string>>,
        default: () => [],
      },
    },

    emits: ['formChanged', 'visibilityChanged'],

    setup(props, { emit }) {
      const answers = {} as Answers
      const children = ref<
        (ComponentPublicInstance | Element | null | ComponentInstanceType)[]
      >([])

      onMounted(() => {
        nextTick(renderOnDefaultValue)
      })

      onBeforeUpdate(() => {
        children.value = []
      })

      function renderOnDefaultValue(): void {
        props.schema.forEach((component) => {
          answers[component.name as string] =
            (component.default as number) || ''
          updateFormComponents(
            component.name as string,
            props.selectedFrequency
          )
        })
      }

      function mutateOnCondition(
        component: ComponentInstanceType,
        frequency: string[]
      ) {
        const results = checkConditions(
          unifyDataFormat(
            component?.conditions as renderConditions[]
          ) as renderConditions[],
          frequency
        )
        for (const [key, result] of Object.entries(results)) {
          component[`should${key.charAt(0).toUpperCase()}${key.slice(1)}`] =
            result
        }
      }

      function updateComponentVisibility(components: ComponentInstanceType[]) {
        let visibleComponents: string[] = []
        components.forEach((child) => {
          if (child['shouldShow']) {
            visibleComponents.push(child['identifier'] as string)
          }
        })
        emit('visibilityChanged', visibleComponents)
      }

      function componentHasConditions(component: ComponentInstanceType) {
        return component.conditions !== undefined
      }

      function matchesComponentByConditionName(
        component: ComponentInstanceType,
        componentName: string
      ) {
        const match = (
          unifyDataFormat(
            component?.conditions as renderConditions[]
          ) as renderConditions[]
        ).find((condition) => condition.on === componentName)
        return match !== undefined && component
      }

      function updateFormComponents(name: string, frequency: string[]): void {
        ;(children.value as ComponentInstanceType[])
          .filter((component) => componentHasConditions(component))
          .filter((component) =>
            matchesComponentByConditionName(component, name)
          )
          .forEach((component) => mutateOnCondition(component, frequency))

        updateComponentVisibility(children.value as ComponentInstanceType[])
      }

      function checkConditions(
        conditions: renderConditions[],
        selectedFrequency: string[]
      ) {
        const conditionsInGroup = groupDoConditions(conditions) as {
          [key: string]: renderConditions[]
        }
        return checkEachConditionGroup(conditionsInGroup, selectedFrequency)
      }

      function groupDoConditions(conditions: renderConditions[]) {
        const initialValue: { [key: string]: renderConditions[] | undefined } =
          {}
        return conditions.reduce((accumulator, condition) => {
          if (accumulator[condition.do.toLowerCase()] !== undefined) {
            ;(
              accumulator[condition.do.toLowerCase()] as renderConditions[]
            ).push(condition)
          } else {
            accumulator[condition.do.toLowerCase()] = [condition]
          }
          return accumulator
        }, initialValue)
      }

      function checkEachConditionGroup(
        group: { [key: string]: renderConditions[] },
        selectedFrequency: string[]
      ) {
        const result: { [key: string]: boolean | boolean[] } = {}
        for (const [key, conditions] of Object.entries(group)) {
          result[key] = conditions.map((condition) => {
            return checkWhenConditions(
              condition.on,
              condition.when,
              selectedFrequency
            )
          })
          result[key] = (result[key] as boolean[]).every((v) => v)
        }
        return result
      }

      function generateValidationExpression(
        value: string | number | renderConditions | frequency,
        equalityOperator: string,
        whenCondition:
          | { [key: string]: string | number }
          | { [key: string]: frequency },
        key: string
      ) {
        return (
          '"' + value + '"' + equalityOperator + '"' + whenCondition[key] + '"'
        )
      }

      function checkWhenConditions(
        componentName: string,
        whenConditions: renderConditions['when'],
        selectedFrequency: string[]
      ) {
        const results = whenConditions.map((whenCondition) => {
          const key = Object.keys(whenCondition)[0]
          const equalityOperator = mapEqualityOperator(key)
          if (equalityOperator === 'frequency') {
            return checkFrequency(whenCondition as frequency, selectedFrequency)
          }
          const answerInArray = unifyDataFormat(answers[componentName])
          return answerInArray.some((value) => {
            const expression = generateValidationExpression(
              value,
              equalityOperator,
              whenCondition as { [key: string]: string | number },
              key
            )
            return Function('"use strict";return (' + expression + ')')()
          })
        })
        return results.some((result: boolean) => result)
      }

      function mapEqualityOperator(key: string): string {
        const equalityOperator: { [key: string]: string | undefined } = {
          eq: '===',
          ge: '>=',
          gt: '>',
          le: '<=',
          lt: '<',
        }
        const frequency = 'frequency'
        if (equalityOperator[key] !== undefined) {
          return equalityOperator[key] as string
        }
        return frequency
      }

      function unifyDataFormat(
        data:
          | string
          | number
          | (string | number)[]
          | renderConditions[]
          | frequency[]
      ) {
        let dataInArray: Array<string | number | renderConditions | frequency> =
          []
        if (Array.isArray(data)) {
          dataInArray = [...data]
        } else {
          dataInArray.push(data)
        }

        return dataInArray
      }

      function checkFrequency(
        whenCondition: frequency,
        selectedFrequency: string[]
      ): boolean {
        return whenCondition.frequency.some((frequency) =>
          selectedFrequency.includes(frequency.toString())
        )
      }

      function rerenderForm(componentName: string, userInput: string) {
        emit('formChanged', { componentName: componentName, value: userInput })
        answers[componentName] = userInput
        updateFormComponents(componentName, props.selectedFrequency)
      }

      return {
        answers,
        children,
        rerenderForm,
      }
    },
  })
</script>
