import ConfirmNearbySite from '../views/ConfirmNearbySite.vue'

export default {
  component: ConfirmNearbySite,
  meta: {
    back: 'selectNearbySite',
    requiresLocation: true,
    showMenu: true,
  },
  name: 'confirmNearbySite',
  path: '/sites/nearby/confirm',
}
