import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useNativeScan = defineStore(
  'native/scan',
  () => {
    const uuid = ref('')

    const reset = () => {
      uuid.value = ''
    }

    return {
      reset,
      uuid,
    }
  },
  {
    persist: true,
  }
)
