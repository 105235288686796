<template>
  <div>
    <div class="p-4">
      <TitleBanner title="Select Site" />
    </div>
    <div class="px-4 pb-4">
      <BaseButton
        class="bypass"
        type="button"
        action="I'M NOT ON SITE"
        @click="bypass"
      />
    </div>
    <div class="px-4">
      <BaseSearchBar
        placeholder="Filter nearby sites..."
        @search="searchQueryChanged"
      />
    </div>
    <div class="px-4">
      <div class="flow-root mt-6">
        <ul class="-my-5 divide-gray-200 divide-y">
          <li
            v-for="site in sites"
            :key="site.id"
            class="py-4"
            @click="selectSite(site)"
          >
            <div class="flex items-center space-x-4">
              <div class="flex-1 min-w-0">
                <p class="font-medium text-gray-900 truncate">
                  {{ site.name }}
                </p>
                <p class="text-gray-500 text-sm truncate">
                  {{ site.address }}
                </p>
              </div>
              <div
                class="bg-white font-medium inline-flex items-center leading-5 px-2.5 py-0.5 text-gray-700"
              >
                <ChevronRightIcon class="h-6 w-6" />
              </div>
            </div>
          </li>
        </ul>
        <div v-if="loading" class="pt-3 text-center">
          <span class="animate-spin-slow w-12 h-12 block mx-auto">
            <img
              id="contentIcon"
              src="../assets/black-spinner-transparent.png"
              class="text-blue-500 h-full w-full"
              alt="icon"
            />
          </span>
          <span>Loading...</span>
        </div>
        <div v-if="noMore" class="py-3 text-center my-4">
          There are no more sites nearby.
        </div>
        <div v-if="noResult" class="py-3 text-center my-4">
          There are no available sites nearby.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, onUnmounted, ref } from 'vue'
  import TitleBanner from '../components/TitleBanner.vue'
  import {
    controller as abortController,
    getSites,
    Site,
  } from '../services/api/site'
  import { useRouter } from 'vue-router'
  import useSite from '../components/use/site'
  import useUser from '../components/use/user'
  import usePosition from '../components/use/position'
  import useCustomer from '../components/use/customer'
  import BaseSearchBar from '../components/BaseSearchBar.vue'
  import useScroll from '../components/use/scroll'
  import config from '../config'
  import { ChevronRightIcon } from '@heroicons/vue/outline'
  import BaseButton from '../components/BaseButton.vue'

  export default defineComponent({
    name: 'SelectNearbySite',
    components: {
      BaseButton,
      BaseSearchBar,
      ChevronRightIcon,
      TitleBanner,
    },

    async setup() {
      const router = useRouter()
      const { setSite, setSiteSelectionConfirmed } = useSite()
      const { getUserId } = useUser()
      const { getUserPosition } = usePosition()
      const { setCustomerInfo } = useCustomer()
      const { addScrollListener, removeScrollListener } =
        useScroll(loadMoreSites)
      const userLocation = getUserPosition()
      const sites = ref<Site[]>([])
      const searchQuery = ref<string | null>(null)
      const loading = ref(false)
      const noMore = ref(false)
      const noResult = ref(false)
      let page = 1

      onMounted(() => {
        setSiteSelectionConfirmed(false)
        addScrollListener()
      })

      onUnmounted(() => {
        removeScrollListener()
      })

      await loadSites(page)

      function bypass(): void {
        router.push({ name: 'bypass' })
      }

      async function loadSites(page: number): Promise<void> {
        loading.value = true
        noMore.value = false
        noResult.value = false
        const response = await getSites(
          getUserId().value.toString(),
          userLocation.value,
          page.toString(),
          searchQuery.value,
          config.distance.radius.user
        )

        if (response) {
          response.length > 0 && sites.value.push(...response)
          manageLoadingState(response)
        }
      }

      function manageLoadingState(data: Site[]) {
        if (data.length === 0 && sites.value.length !== 0) {
          noMore.value = true
        } else if (data.length === 0 && sites.value.length === 0) {
          noResult.value = true
        }
        loading.value = false
      }

      function selectSite(site: Site): void {
        setSite(site)
        setCustomerInfo(site.customer)
        router.push({ name: 'confirmNearbySite' })
      }

      async function searchQueryChanged(search: string): Promise<void> {
        abortController.abort()
        page = 1
        searchQuery.value = search ? search : null
        sites.value = []
        await loadSites(page)
      }

      async function loadMoreSites(): Promise<void> {
        if (!loading.value && !noMore.value && !noResult.value) {
          page++
          await loadSites(page)
        }
      }

      return {
        bypass,
        loading,
        noMore,
        noResult,
        searchQueryChanged,
        selectSite,
        sites,
      }
    },
  })
</script>
