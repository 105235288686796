import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import AppEntryPoint from '../views/AppEntryPoint.vue'

const guard = (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  next({ name: 'login' })
}

export default {
  beforeEnter: guard,
  component: AppEntryPoint,
  meta: {
    showMenu: false,
  },
  name: 'home',
  path: '/',
}
