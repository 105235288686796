export default `
  mutation(
    $geoLocation: String!,
    $deviceInfo: String!,
    $jobStart: String!,
    $jobEnd: String!,
    $userId: String!,
    $customerId: String!,
    $siteId: String!,
    $serviceId: String!,
    $subServiceId: String!,
    $frequencies: String!,
    $systemRemark: String!,
    $visitLogId: String!,
    $contractorCompanyId: Int,
    $lat: Float!,
    $long: Float!,
    $alt: Float,
    $accuracy: Float!,
    $formData: String!,
    $originalSchema: String!,
  ) {
    storeServiceRecord(
        geoLocation: $geoLocation,
        deviceInfo: $deviceInfo,
        jobStart: $jobStart,
        jobEnd: $jobEnd,
        userId: $userId,
        customerId: $customerId,
        siteId: $siteId,
        serviceId: $serviceId,
        subServiceId: $subServiceId,
        frequencies: $frequencies,
        systemRemark: $systemRemark,
        visitLogId: $visitLogId,
        contractorCompanyId: $contractorCompanyId,
        lat: $lat,
        long: $long,
        alt: $alt,
        accuracy: $accuracy,
        formData: $formData,
        originalSchema: $originalSchema,
    ) {
      id
    }
  }
`
