export default `
query SITES_QUERY($userId: String!, $location: Location!, $searchQuery: String, $withinRadius: Int, $pageNumber: String, $perPage: Int) {
  sites(userId: $userId, location: $location, searchQuery: $searchQuery, withinRadius: $withinRadius, pageNumber: $pageNumber, perPage: $perPage) {
    data{
      id
      name
      longitude
      latitude
      distance
      address
      customer{
        id
        name
        street
        town
        postcode
        state
        phone
        email
        hasInduction
        hasWorkOrder
        hasUnregisteredContractor
        hasCheckList
        hasNotice
        hasGPS
        hasAccreditation
      }
    }
  }
}
`
