export default `
  mutation(
    $photoId: String!,
    ){
    deletePhoto(
        photoId: $photoId
    ){
        message
        code
    }
}
`
