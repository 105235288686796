import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { positionResolver, timezoneResolver } from './routes/resolvers'
import {
  detectDesktopDeviceGuard,
  loginHistoryGuard,
  noPrivateBrowsingGuard,
} from './routes/guards'
import { savePointFinaliser } from './routes/finalisers'

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeResolve(positionResolver)
router.beforeResolve(timezoneResolver)
router.beforeEach(detectDesktopDeviceGuard)
router.beforeEach(noPrivateBrowsingGuard)
router.beforeEach(loginHistoryGuard)
router.afterEach(savePointFinaliser)

export default router
