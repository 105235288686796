import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useCustomer = defineStore(
  'customer',
  () => {
    const email = ref('')
    const hasAccreditation = ref(false)
    const hasCheckList = ref(false)
    const hasGPS = ref(false)
    const hasInduction = ref(false)
    const hasNotice = ref(false)
    const hasVisitorManagement = ref(false)
    const hasWorkOrder = ref(false)
    const id = ref('')
    const name = ref('')
    const phone = ref('')
    const postcode = ref('')
    const state = ref('')
    const street = ref('')
    const town = ref('')

    const reset = () => {
      email.value = ''
      hasAccreditation.value = false
      hasCheckList.value = false
      hasGPS.value = false
      hasInduction.value = false
      hasNotice.value = false
      hasVisitorManagement.value = false
      hasWorkOrder.value = false
      id.value = ''
      name.value = ''
      phone.value = ''
      postcode.value = ''
      state.value = ''
      street.value = ''
      town.value = ''
    }

    return {
      email,
      hasAccreditation,
      hasCheckList,
      hasGPS,
      hasInduction,
      hasNotice,
      hasVisitorManagement,
      hasWorkOrder,
      id,
      name,
      phone,
      postcode,
      reset,
      state,
      street,
      town,
    }
  },
  {
    persist: true,
  }
)
