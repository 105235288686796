import * as t from 'io-ts'
import { pipe } from 'fp-ts/function'
import { fold } from 'fp-ts/Either'
import { PathReporter } from 'io-ts/PathReporter'

export default function decodeToPromise<T, O, I>(
  validator: t.Type<T, O, I>,
  input: I
): Promise<T> {
  const result = validator.decode(input)

  return pipe(
    result,
    fold(
      () => {
        const messages = PathReporter.report(result)
        return Promise.reject(new Error(messages.join('\n')))
      },
      (value) => Promise.resolve(value)
    )
  )
}
