<template>
  <div v-if="shouldShow" class="my-4" :class="identifier">
    <h4 class="p-1 pl-0" :class="{ 'text-red-500': !!errorMessage }">
      {{ content }}
    </h4>
    <div
      v-for="option in options"
      :key="options.indexOf(option)"
      class="m-1 ml-0"
    >
      <input
        :id="identifier + option?.label"
        v-model="selectedOption"
        type="checkbox"
        :value="option?.value"
        class="border-gray-500 h-4 rounded-sm text-blue-500 w-4"
        @change="newOptionSelected($event.target)"
      />
      <label
        :for="identifier + option?.label"
        class="m-2"
        :class="{ 'text-red-500': !!errorMessage }"
      >
        {{ option?.label }}
      </label>
    </div>
    <span class="mt-1 mb-1 text-red-500">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, ref, toRefs } from 'vue'
  import { optionType } from '../../config'

  export default defineComponent({
    name: 'CheckboxInput',

    props: {
      // eslint-disable-next-line vue/no-unused-properties
      conditions: {
        type: Array,
        default: () => undefined,
      },

      content: {
        type: String,
        default: 'question',
      },

      errorMessage: {
        type: String,
        default: '',
      },

      name: {
        type: String,
        default: '',
      },

      options: {
        type: Array as PropType<optionType[]>,
        default: () => [],
      },

      show: {
        type: Boolean,
        default: false,
      },

      specialOption: {
        type: String,
        default: '',
      },
    },

    emits: ['formChanged'],

    setup(props, { emit }) {
      const selectedOption = ref<string[]>([])
      const identifier = ref<string>(props.name)
      const { specialOption } = toRefs(props)
      const shouldShow = ref(props.show)

      function newOptionSelected(event: EventTarget | null) {
        const newOption = (event as HTMLInputElement)?.value
        if (newOption === specialOption.value) {
          selectedOption.value = [specialOption.value]
        } else if (selectedOption.value.includes(specialOption.value)) {
          selectedOption.value.splice(
            selectedOption.value.indexOf(specialOption.value)
          )
          selectedOption.value.push(newOption)
        }
        emit('formChanged', selectedOption.value)
      }

      return {
        identifier,
        newOptionSelected,
        selectedOption,
        shouldShow,
      }
    },
  })
</script>
