<template>
  <div>
    <TitleBanner title="Help" />
    <div class="text-center my-4">
      <span class="text-xl">Device Info</span>
    </div>
    <div class="text-center">
      <div>
        <ul>
          <li><span class="font-bold">OS:</span> {{ deviceInfoOs }}</li>
          <li>
            <span class="font-bold">Browser:</span> {{ deviceInfoBrowser }}
          </li>
          <li>
            <span class="font-bold">Browser Version:</span>
            {{ deviceInfoBrowserVersion }}
          </li>
          <li>
            <span class="font-bold">GPS Location On: </span>
            {{ allowGpsLocation }}
          </li>
        </ul>
      </div>
      <div class="text-center my-4">
        <span class="text-xl">Verified Info</span>
      </div>
      <div class="mb-3">
        <span class="font-bold">Build Version:</span> {{ versionNo }}
      </div>
      <div class="text-center my-4">
        <span class="text-xl">Call Verified</span>
      </div>
      <div>
        <div class="my-4">
          <a class="underline text-blue-500" href="tel:1300133950">
            <span class="font-bold">AUS:</span> 1300 133 950
          </a>
        </div>
        <div class="my-4">
          <a class="underline text-blue-500" href="tel:0800000854">
            <span class="font-bold">NZ:</span> 0800 000 854
          </a>
        </div>
      </div>
      <div class="mt-12 mb-4 underline text-blue-500">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://webcontent.verified.com.au/terms-and-conditions/"
          >Terms & Conditions</a
        >
      </div>
      <div class="my-4 underline text-blue-500">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://webcontent.verified.com.au/privacy-policy/"
          >Privacy Policy</a
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import TitleBanner from '../../components/TitleBanner.vue'
  import { defineComponent, ref } from 'vue'
  import mobileDetect from 'mobile-detect'
  import usePosition from '../../components/use/position'
  import { version } from '../../../package.json'

  export default defineComponent({
    name: 'UserHelp',
    components: {
      TitleBanner,
    },

    setup() {
      const md = new mobileDetect(window.navigator.userAgent)
      const allowGpsLocation = ref('')
      const deviceInfoOs = ref('')
      const deviceInfoBrowser = ref('')
      const deviceInfoBrowserVersion = ref('')
      const versionNo = version
      const { getUserPosition } = usePosition()

      if (md.os() == 'iOS') {
        deviceInfoOs.value =
          md.os() + ' ' + md.versionStr('iOS').replace(/_/g, '.')
        deviceInfoBrowser.value = md.userAgent()
        deviceInfoBrowserVersion.value = md.versionStr('Safari')
      } else if (md.os() == 'AndroidOS') {
        deviceInfoOs.value =
          md.os().substring(0, md.os().length - 2) +
          ' ' +
          md.versionStr('Android')
        deviceInfoBrowser.value = md.userAgent()
        deviceInfoBrowserVersion.value = md.versionStr('Chrome').substring(0, 2)
      } else if (md.os() == 'WindowsPhoneOS') {
        deviceInfoOs.value =
          md.os().substring(0, md.os().length - 2) +
          ' ' +
          md.versionStr('Windows Phone OS')
        deviceInfoBrowser.value = md.userAgents()[1]
        deviceInfoBrowserVersion.value = md.versionStr('Edge').substring(0, 2)
      }

      if (
        getUserPosition().value.latitude !== 0 &&
        getUserPosition().value.longitude !== 0
      ) {
        allowGpsLocation.value = 'Yes'
      } else {
        allowGpsLocation.value = 'No'
      }

      return {
        allowGpsLocation,
        deviceInfoBrowser,
        deviceInfoBrowserVersion,
        deviceInfoOs,
        versionNo,
      }
    },
  })
</script>
