<template>
  <div class="bg-white flex items-center relative">
    <input
      id="search"
      v-model="search"
      :class="[identifier, buttonClasses]"
      class="focus:outline-none leading-tight px-6 py-4 rounded-md text-gray-700 w-full"
      type="text"
      @mousedown="showIcon"
      @blur="blur"
    />
    <span
      v-if="!search && iconShown"
      id="searchIcon"
      class="absolute p-4 pointer-events-none text-gray-500"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    </span>
    <span
      v-if="!search && iconShown"
      id="clearIcon"
      class="absolute pl-12 pointer-events-none text-gray-500"
      >{{ placeholder }}</span
    >
    <button class="absolute right-4" type="button" @click="clear">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, watch } from 'vue'

  export default defineComponent({
    name: 'BaseSearchBar',

    props: {
      buttonClasses: {
        type: Array,
        default: () => [],
      },

      identifier: {
        type: String,
        default: '',
      },

      placeholder: {
        type: String,
        default: 'Search...',
      },
    },

    emits: ['search'],

    setup(props, { emit }) {
      const search = ref('')
      const iconShown = ref(true)

      function blur(): void {
        iconShown.value = true
      }

      function clear(): void {
        search.value = ''
        iconShown.value = true
      }

      function showIcon(): void {
        iconShown.value = false
      }

      watch(search, () => {
        emit('search', search.value)
      })

      return {
        blur,
        clear,
        iconShown,
        search,
        showIcon,
      }
    },
  })
</script>
