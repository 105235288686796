import { defineStore } from 'pinia'
import { ref } from 'vue'
import { ContractorCompany } from '../services/api/user'
import { getSite } from '../services/api/site'
import useCustomer from '../components/use/customer'

export const useSite = defineStore(
  'site',
  () => {
    const address = ref('')
    const authorisedContractorCompanies = ref([
      { id: 0, name: '' },
    ] as ContractorCompany[])
    const exists = ref(false)
    const name = ref('')
    const id = ref(0)
    const latitude = ref(0)
    const longitude = ref(0)
    const selectionConfirmed = ref(false)

    const loadSite = async (code: string) => {
      const { setCustomerInfo } = useCustomer()

      try {
        const site = await getSite(code)

        address.value = site.address
        exists.value = !!site
        id.value = site.id
        latitude.value = site.latitude
        longitude.value = site.longitude
        name.value = site.name

        setCustomerInfo(site.customer)
      } catch (_) {
        exists.value = false
      }
    }

    const reset = () => {
      address.value = ''
      authorisedContractorCompanies.value = [{ id: 0, name: '' }]
      exists.value = false
      id.value = 0
      latitude.value = 0
      longitude.value = 0
      name.value = ''
      selectionConfirmed.value = false
    }

    return {
      address,
      authorisedContractorCompanies,
      exists,
      id,
      latitude,
      loadSite,
      longitude,
      name,
      reset,
      selectionConfirmed,
    }
  },
  {
    persist: true,
  }
)
