import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import CheckoutView from '../views/CheckoutView.vue'

const guard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  from.name === 'thankYouPage' ? next({ name: 'home' }) : next()
}

export default {
  beforeEnter: guard,
  component: CheckoutView,
  meta: {
    back: '',
    requiresLocation: true,
    showMenu: true,
  },
  name: 'checkout',
  path: '/checkout',
}
