import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

const loginHistoryGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (window.history.state.back === null && to.name !== 'login') {
    delete to.meta.back
  }
  next()
}

export { loginHistoryGuard }
