import { instruction } from '../'
import threeDotsIcon from '../../../src/assets/three-dots.png'
import chromeIcon from '../../../src/assets/chrome.png'
import safariIcon from '../../../src/assets/safari.jpg'
import settingIcon from '../../../src/assets/setting.png'

const android: instruction = {
  content: [
    'Tap the 3 dots at top right corner',
    'Select Settings',
    'Select Site Settings',
    'Select Location and enable',
    'Go back to Verified login page',
  ],
  contentIcon: threeDotsIcon,
  device: 'android',
  title: 'Open Google Chrome',
  titleIcon: chromeIcon,
}

const ios: instruction = {
  content: [
    'Tap Safari and scroll down',
    'Tap "Location" and tap Allow',
    'Go back to Verified login page',
  ],

  contentIcon: safariIcon,
  device: 'ios',
  title: 'Open iPhone Settings',
  titleIcon: settingIcon,
}

const iosDeviceList = ['ios', 'mac os x']

export default {
  android: android,
  ios: ios,
  iosDeviceList: iosDeviceList,
}
