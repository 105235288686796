import { useNativeScan } from '../../storage/useNativeScan'

export default function site(): {
  resetNativeScan: () => void
} {
  const store = useNativeScan()
  function resetNativeScan(): void {
    store.reset()
  }

  return {
    resetNativeScan,
  }
}
