<template>
  <TitleBanner title="Who are you representing" />
  <ul class="mt-10">
    <li
      v-for="contractorCompany in contractorCompanies"
      :key="contractorCompanies.indexOf(contractorCompany)"
      class="w-4/5 mx-auto mb-4"
    >
      <BaseButton
        :action="contractorCompany.name"
        @click="selectContractorCompany(contractorCompany.id)"
      />
    </li>
  </ul>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import TitleBanner from '../components/TitleBanner.vue'
  import BaseButton from '../components/BaseButton.vue'
  import useBypass from '../components/use/bypass'
  import useUser from '../components/use/user'
  import useSite from '../components/use/site'
  import { useRouter } from 'vue-router'
  import { postSiteSelectedData } from '../services/api/siteSelected'
  import usePosition from '../components/use/position'
  import siteSelected, {
    SiteSelectedData,
  } from '../components/use/siteSelected'

  export default defineComponent({
    name: 'ContractorCompanySelection',
    components: { BaseButton, TitleBanner },
    async setup() {
      const { getBypass } = useBypass()
      const { getUserId } = useUser()
      const { getSiteId } = useSite()
      const { getUserPosition } = usePosition()
      const {
        setSelectedContractorCompanyId,
        getUserSelectedContractorCompanyId,
      } = useUser()
      const { getSiteAuthorisedContractorCompanies } = useSite()
      const contractorCompanies = getSiteAuthorisedContractorCompanies()
      const router = useRouter()

      if (contractorCompanies.value.length === 1) {
        await selectContractorCompany(contractorCompanies.value[0].id)
      }

      async function acquireRequiredData(): Promise<SiteSelectedData> {
        const bypass = getBypass()
        const contractorCompanyId = getUserSelectedContractorCompanyId()
        const location = getUserPosition()
        const siteId = getSiteId()
        const userId = getUserId()

        return {
          bypass: bypass,
          contractorCompanyId: contractorCompanyId.value.toString(),
          location: {
            accuracy: location.value.accuracy,
            altitude: location.value.altitude,
            latitude: location.value.latitude,
            longitude: location.value.longitude,
          },

          siteId: siteId.value.toString(),
          userId: userId.value.toString(),
        }
      }

      async function selectContractorCompany(id: number) {
        setSelectedContractorCompanyId(id)
        const response = await siteSelected(
          acquireRequiredData,
          postSiteSelectedData
        )
        response.code === 200 &&
          (await router.push({ name: 'serviceSelection' }))
      }

      return {
        contractorCompanies,
        selectContractorCompany,
      }
    },
  })
</script>
