import { CheckoutResponse } from '../../services/api/checkout'

type DataAcquisitionFunction = () => Promise<CheckoutData>

export type CheckoutData = {
  siteId: string
  userId: string
  location: Position
}

type ApiCallFunction = (data: CheckoutData) => Promise<CheckoutResponse>

const checkout = async function (
  acquireData: DataAcquisitionFunction,
  apiCall: ApiCallFunction
): Promise<CheckoutResponse> {
  return await apiCall(await acquireData())
}

export default checkout
