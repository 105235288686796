import BypassSite from '../views/BypassSite.vue'

export default {
  component: BypassSite,
  meta: {
    back: 'selectNearbySite',
    showMenu: true,
  },
  name: 'bypass',
  path: '/bypass',
}
