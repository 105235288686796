export default `
  mutation AUTHENTICATE_USER ($phone: String!, $pin: String!) {
    authenticateUser(phone: $phone, pin: $pin) {
      id
      firstname
      lastname
      name
      phone
      contractorCompanies {
        id
        name
      }
      contractorEmployee {
        id
      }
    }
  }
`
