import { defineStore } from 'pinia'
import { ref } from 'vue'
import { Service } from '../services/api/services'

export type CompletableService = Service & { completed: boolean }

export const useServices = defineStore(
  'services',
  () => {
    const allServices = ref([
      {
        completed: false,
        id: '',
        name: '',
        subServiceId: '',
      },
    ] as CompletableService[])
    const selectedService = ref({
      id: '',
      name: '',
      subServiceId: '',
    } as Service)
    const selectedServiceType = ref('')

    const reset = () => {
      allServices.value = [
        {
          completed: false,
          id: '',
          name: '',
          subServiceId: '',
        },
      ]
      selectedService.value = {
        id: '',
        name: '',
        subServiceId: '',
      }
      selectedServiceType.value = ''
    }

    const setServices = (services: CompletableService[]) => {
      allServices.value[0].id === '' && allServices.value.shift()
      services?.map((service) => {
        const identical = allServices.value.filter(
          (serviceInStore: Service) =>
            serviceInStore?.id === service?.id &&
            serviceInStore?.subServiceId === service?.subServiceId
        )
        identical.length === 0 && allServices.value.push(service)
      })
    }

    const setCompleted = (serviceId: string, subServiceId: string) => {
      const service = allServices.value.find(
        (service) =>
          service.id === serviceId && service.subServiceId === subServiceId
      )
      if (service !== undefined) {
        service.completed = true
      }
    }

    return {
      allServices,
      reset,
      selectedService,
      selectedServiceType,
      setCompleted,
      setServices,
    }
  },
  {
    persist: true,
  }
)
