import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { getSite, Site } from '../services/api/site'
import SelectNearbySite from '../views/SelectNearbySite.vue'
import useSite from '../components/use/site'
import useCustomer from '../components/use/customer'
import { useNativeScan } from '../storage/useNativeScan'

const guard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const store = useNativeScan()
  const uuid = store.uuid
  if (uuid?.length === 36) {
    const { resetSite, setSite } = useSite()
    const { resetCustomerData, setCustomerInfo } = useCustomer()

    getSite(uuid)
      .then((site: Site) => {
        setSite(site)
        setCustomerInfo(site.customer)
        next({ name: 'confirmNearbySite' })
      })
      .catch(() => {
        resetSite()
        resetCustomerData()
        next()
      })
  } else {
    next()
  }
}

export default {
  beforeEnter: guard,
  component: SelectNearbySite,
  meta: {
    back: 'home',
    requiresLocation: true,
    showMenu: true,
  },
  name: 'selectNearbySite',
  path: '/sites/nearby',
}
