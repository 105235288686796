import axios from 'axios'
import { SiteSelectedData } from '../../components/use/siteSelected'
import siteSelectedQuery from '../../graphql/mutations/siteSelected'
import * as io from 'io-ts'
import decodeToPromise from './typeChecker'

const siteSelectedResponseTypeChecker = io.type({
  code: io.number,
  message: io.string,
})

export type SiteSelectedResponse = io.TypeOf<
  typeof siteSelectedResponseTypeChecker
>

export const postSiteSelectedData = async (
  siteSelectedData: SiteSelectedData
): Promise<SiteSelectedResponse> => {
  try {
    const response = await axios.post(
      '/graphql',
      {
        query: siteSelectedQuery,
        variables: { ...siteSelectedData },
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      }
    )
    const data = response?.data?.data?.siteSelected as SiteSelectedResponse
    return await decodeToPromise(siteSelectedResponseTypeChecker, data)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw error.message
  }
}
