<template>
  <span
    v-if="iso"
    :class="[flag, mode]"
    :title="title || iso"
    class="fi"
  ></span>
</template>

<script lang="ts">
  import 'flag-icons/css/flag-icons.css'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'CountryFlag',

    props: {
      iso: {
        required: true,
        type: String,
      },

      squared: {
        default: false,
        type: Boolean,
      },

      title: {
        default: null,
        type: String,
      },
    },

    computed: {
      flag(): string {
        return 'fi-' + this.iso.toLowerCase()
      },

      mode(): string {
        return this.squared ? 'fis' : ''
      },
    },
  })
</script>
