import { RouteLocationNormalized } from 'vue-router'
import usePosition from '../../components/use/position'

export const positionResolver = async (to: RouteLocationNormalized) => {
  if (to.meta.requiresLocation) {
    try {
      await usePosition().setUserPosition()
    } catch (error) {
      return { name: 'helpLocationPermission' }
    }
  }
}
