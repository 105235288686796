import axios from 'axios'
import queryServiceRecordsByUser from '../../graphql/queries/queryServiceRecordsByUser'
import config from '../../config'
import * as io from 'io-ts'
import decodeToPromise from './typeChecker'

export type ServiceRecord = io.TypeOf<typeof serviceRecord>
export let controller: AbortController = new AbortController()

const compulsoryProperties = io.type({
  id: io.string,
  serviceName: io.string,
  siteName: io.string,
})

const optionalProperties = io.partial({
  date: io.string,
  frequencyCodes: io.array(io.union([io.string, io.null])),
})

const serviceRecord = io.intersection([
  compulsoryProperties,
  optionalProperties,
])

const serviceRecordTypeChecker = io.array(serviceRecord)

export const getServiceRecordByUser = async (
  userId: number,
  page: string,
  searchQuery: string | null = null,
  serviceDate: string | null = null
): Promise<ServiceRecord[] | undefined> => {
  controller = new AbortController()
  try {
    const response = await axios.get('/graphql', {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        query: queryServiceRecordsByUser,
        variables: {
          page: page,
          perPage: config.pagination.sites.perPage.toString(),
          searchQuery: searchQuery,
          serviceDate: serviceDate,
          userId: userId,
        },
      },
      signal: controller.signal,
    })
    const data = response.data?.data?.serviceRecords
    return decodeToPromise(serviceRecordTypeChecker, data)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.message !== 'canceled') {
      return []
    }
  }
}
